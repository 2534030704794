import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const router = useRouter();
    const {
      public: { sentry },
    } = useRuntimeConfig();

    const sentryDsn = sentry.dsn ||"";
    if (!sentryDsn) {
      return;
    }
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentryDsn,
      environment: sentry.environment,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],

      // Configure this whole part as you need it!

      tracesSampleRate: 0.2, // Change in prod

      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line no-console
          console.error(
            `[Exeption handled by Sentry]: (${hint.originalException})`,
            { event, hint }
          );
        }
        // Continue sending to Sentry
        return event;
      },
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        '*',
      ],

      replaysSessionSampleRate: 1.0, // Change in prod
      replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  }
});
