<template>
  <div>
    <div
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>
    <Sidenav />
    <main class="main-content position-relative border-radius-lg">
      <slot />
    </main>
  </div>
</template>

<script setup>
import Sidenav from "@/examples/Sidenav";
import { useNavStore } from "~~/stores/NavStore";
const navStore = useNavStore();
navStore.sidenavType = "bg-default bg-transparent";

</script>
