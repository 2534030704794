<template>
  <div>
    <main class="main-content mt-0">
      <slot />
    </main>
  </div>
</template>
<script setup>
useHead({
  bodyAttrs: {
    class: 'bg-gray-100 rtl',
  },
  htmlAttrs: {
    lang: 'ar',
    dir: 'rtl',
  },
});
</script>
