<template>
  <nav
    id="navbarBlur"
    :class="
      navStore.navFixed
        ? `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky left-auto top-2 z-index-sticky ${
            navStore.darkMode ? 'bg-default' : 'bg-white'
          }`
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none`
    "
    v-bind="$attrs"
    data-scroll="true"
  >
    <!-- Large Screen  -->
    <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none me-3">
      <a href="#" class="p-0 nav-link text-body" @click.prevent="toggleSidenav">
        <div class="sidenav-toggler-inner">
          <i
            class="sidenav-toggler-line"
            :class="
              navStore.navFixed && !navStore.darkMode
                ? ' opacity-8 bg-dark'
                : 'bg-white'
            "
          ></i>
          <i
            class="sidenav-toggler-line"
            :class="
              navStore.navFixed && !navStore.darkMode
                ? ' opacity-8 bg-dark'
                : 'bg-white'
            "
          ></i>
          <i
            class="sidenav-toggler-line"
            :class="
              navStore.navFixed && !navStore.darkMode
                ? ' opacity-8 bg-dark'
                : 'bg-white'
            "
          ></i>
        </div>
      </a>
    </div>
    <div class="px-3 py-1 container-fluid">
      <Breadcrumbs is-r-t-l />
      <li
        class="nav-item d-xl-none ps-3 d-flex align-items-center me-4 position-absolute mt-1 ms-5 ms-sm-4 ms-md-0 start-10 start-md-8 top-20 mt-2"
      >
        <a
          id="iconNavbarSidenav"
          href="#"
          class="p-0 nav-link text-white"
          @click.prevent="toggleSidenav"
        >
          <div class="sidenav-toggler-inner">
            <i
              :class="`sidenav-toggler-line ${
                navStore.navFixed && !navStore.darkMode
                  ? ' opacity-8 bg-dark'
                  : 'bg-white'
              }`"
            ></i>
            <i
              :class="`sidenav-toggler-line ${
                navStore.navFixed && !navStore.darkMode
                  ? ' opacity-8 bg-dark'
                  : 'bg-white'
              }`"
            ></i>
            <i
              :class="`sidenav-toggler-line ${
                navStore.navFixed && !navStore.darkMode
                  ? ' opacity-8 bg-dark'
                  : 'bg-white'
              }`"
            ></i>
          </div>
        </a>
      </li>

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 px-0"
      >
        <ul class="navbar-nav justify-content-end me-md-auto">
          <li
            class="nav-item dropdown d-flex align-items-center ps-2 mt-0 mt-sm-1 me-3"
          >
            <a
              id="dropdownMenuButton1"
              href="#"
              :class="`p-0 nav-link ${
                navStore.navFixed && !navStore.darkMode
                  ? ' opacity-8 text-dark'
                  : 'text-white'
              } ${showMenu ? 'show' : ''}`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <ArgonAvatar
                v-if="authStore.auth.user.avatar"
                :image="authStore.auth.user.avatar"
                size="xs"
                circular
                alt="user image"
                class="dropdown-toggle avatar-menu-toggle me-2 cursor-pointer mt-2"
              />
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-start me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton1"
            >
              <li class="d-flex align-items-center px-2">
                <i class="fa fa-user"></i>
                <NuxtLink
                  to="/profile"
                  class="dropdown-item border-radius-md text-end"
                >
                  الملف الشخصي
                </NuxtLink>
              </li>
              <li
                @click="authStore.logout"
                class="d-flex align-items-center px-2"
              >
                <i class="fa fa-sign-out fa-rotate-180"></i>
                <NuxtLink
                  class="dropdown-item border-radius-md text-end"
                  href="javascript:;"
                >
                  تسجيل الخروج
                </NuxtLink>
              </li>
            </ul>
          </li>

          <li
            class="nav-item dropdown d-flex align-items-center ps-2 mt-0 mt-sm-1 me-3"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              :class="`p-0 nav-link ${
                navStore.navFixed && !navStore.darkMode
                  ? ' opacity-8 text-dark'
                  : 'text-white'
              } ${showMenu ? 'show' : ''}`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup>
import Breadcrumbs from "../Breadcrumbs";
import { useNavStore } from "~~/stores/NavStore";
import { useAuthStore } from "~~/stores/AuthStore";

const navStore = useNavStore();

const authStore = useAuthStore();
function toggleSidenav() {
  navStore.toggleSidenavCollapsing();
}

const showMenu = ref(false);
</script>
