<template>
    <div class="d-flex mt-3">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="py-2 px-4 rounded-top bg-white ms-2"
        :class="{ notActive: activeTab != tab.id }"
        @click="activeTab = tab.id"
      >
        <span class="text-black text-14 font-weight-bolder">{{ tab.title }}</span>
      </div>
    </div>
    <div>
      <slot :activeTab="activeTab"></slot>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeTab: "sessionsBalance",
        tabs: [
          { id: "sessionsBalance", title: "سجل رصيد الجلسات" },
          { id: "transfers", title: "سجل التحويلات" },
        ],
      };
    },
  };
  </script>
  <style scoped>
  .notActive {
    opacity: 0.7;
  }
  .notActive span {
    color: #d5e0f9;
  }
  </style>
  