<template>
  <div class="form-group">
    <label :for="id">
      {{ label }}
      <slot />
    </label>
    <textarea
      :id="id"
      class="form-control"
      :class="getClasses(size, success, error, errorMessage)"
      :rows="rows"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <div v-if="errorMessage.length" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArgonTextarea',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 5,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getClasses: (size, success, error, errorMessage) => {
      let sizeValue, isValidValue;
      // eslint-disable-next-line prefer-const
      sizeValue = size ? `form-control-${size}` : '';

      if (error || errorMessage.length) {
        isValidValue = 'is-invalid';
      } else if (success) {
        isValidValue = 'is-valid';
      } else {
        isValidValue = '';
      }

      return `${sizeValue} ${isValidValue}`;
    },
  },
};
</script>
