<template>
  <div>
    <label :for="id" class="custom-control-label float-end">
      {{ label }}
    </label>
    <Multiselect
      v-model="value"
      :id="id"
      :placeholder="placeholder"
      :mode="mode"
      :label="returnKeys.text"
      :valueProp="returnKeys.value"
      :options="options"
      :object="object"
      :searchable="true"
      :disabled="isDisabled"
      :infinite="infinite"
      @search-change="onSearchChange"
    >
    </Multiselect>
    <div v-if="errorMessage.length" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: { Multiselect },
  props: {
    modelValue: {
      type: [Array, Object,String, Boolean, Number],
      required: true,
    },
    id: {
      type: String,
      default: 'multiselect',
    },
    options: {
      type: [Array, Object],
      default: [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
    returnKeys: {
      type: Object,
      default: {
        text: 'name',
        value: 'id',
      },
    },
    label: {
      type: String,
      default: '',
    },
    object: {
      type: Boolean,
      default: true,
    },
    selectType: {
      type: String,
      default: 'string',
    },
    mode: {
      type: String,
      default: 'tags', // single | multiple | tags
    },
    placeholder: {
      type: String,
      default: 'إختر',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    returnSingleValue: {
      type: Boolean,
      default: false,
    },
    selectedFirstIndex: {
      type: Boolean,
      default: false,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
    clearSelectedValue: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(newValue) {
      if (this.returnSingleValue) {
        if(newValue)
        this.$emit('update:modelValue', newValue[this.key]);
      } else {
        this.$emit('update:modelValue', newValue);
      }
    },
    modelValue(newValue) {
      if (!newValue && !this.returnSingleValue) {
        this.value = null;
      }
    },
    clearSelectedValue(newValue) {
      if(!newValue)
      this.value = ''
    }
  },
  mounted() {
    this.putDefultValue();
  },
  methods: {
    putDefultValue() {
      if (this.selectedFirstIndex === true) {
        this.value = this.options[0];
      }
    },
    onSearchChange(searchText) {
      this.searchText = searchText
      this.$emit('search-text', searchText)
    }
  },
  data() {
    return {
      value: this.modelValue,
      key: this.returnKeys.value,
      searchText: '',
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect * {
  font-size: 0.8rem !important;
}
.multiselect-tag {
  background-color: #212529;
  border: 1px solid #212529;
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 12px !important;
  font-weight: 500;
  margin-bottom: 3.75px;
  margin-right: 3.75px;
  padding: 5px 7px 7px 0 !important;
}
.multiselect-tag span{
  color: #fff;
}
</style>
