/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
const { checkStatusCode } = useCheckRequestStatusCode();

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    email: '',
    password: '',
    serverErrors: {},
    isActivated: false,
    activationMessage: 'تم تفعيل بريدك الإلكتروني بنجاح',
    loading: false,
    btnLoading: false,
    deleteLoading: false,
    coachesFromRealTimeDb: [],
    auth: {
      user: {
        avatar: '',
        email: '',
        firstName: '',
        id: '',
        lastName: '',
        role: '',
      },
      token: '',
      isLoggedIn: false,
      headers: {
        accept: '*/*',
      },
    },
    signUp: {
      personalData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        passwordConfirmation: '',
        nationality: '',
        address: '',
        gender: '',
        birthDate: '',
      },
      professionalData: {
        avatar: '',
        introduction: '',
        qualification: '',
        class: '',
        degree: '',
        specialization: '',
        category: '',
        language: '',
      },
    },
  }),
  getters: {
    fullName(state) {
      return state.auth.user.firstName + ' ' + state.auth.user.lastName;
    },
  },
  actions: {
    async login(credential) {
      const { setValue } = useLocalStorage();

      const formData = new FormData();
      for (const key in credential) {
        formData.append(key, credential[key]);
      }
      this.loading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/login`,

        {
          method: 'POST',
          body: formData,
        }
      );

      if (!error.value) {
        setValue('token', response.value.data.token); // Store Token in LocalStorage
        this.auth.token = response.value.data.token;
        this.setHeaders(response.value.data.token);
        if (response.value.statusCode === 200) {
          await this.getProfile();
        }
      } else {
        this.serverErrors = error.value.data.errors;
      }
      this.loading = false;
    },

    async getProfile() {
      if (this.auth.headers.Authorization) {
        const userPermissions = usePermissions();
        const userRoles = useRoles();
        const route = useRoute();
        this.loading = true;
        const { data: response, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/profile`,
          {
            method: 'GET',
            headers: this.auth.headers,
          }
        );
        this.loading = false;
        if (!error.value) {
          this.auth.isLoggedIn = true;
          this.auth.user = response.value.data;
          userPermissions.value = this.auth.user.permissions;
          userRoles.value = this.auth.user.role;

          if (route.name === 'auth-signin') {
            return navigateTo({ path: '/' });
          }
        } else {
          checkStatusCode(error.value.data.statusCode);
        }
      }
    },

    async updateProfile(updatedProfileData) {
      const formData = new FormData();
      formData.append('_method', 'patch');
      for (const key in updatedProfileData) {
        if (
          key !== 'id' &&
          key !== 'updatedAt' &&
          key !== 'createdAt' &&
          key !== 'createdAt' &&
          key !== 'Password' &&
          key !== 'avatar' &&
          this.auth.user[key] !== updatedProfileData[key]
        ) {
          formData.append(key, updatedProfileData[key]);
        } else if (
          key === 'avatar' &&
          typeof updatedProfileData[key] !== 'string'
        ) {
          formData.append(key, updatedProfileData[key]);
        } else if (
          key === 'password' &&
          updatedProfileData[key].newPassword.length
        ) {
          formData.append(key, updatedProfileData[key]);
        }
      }
      for (const key in updatedProfileData.Password) {
        if (updatedProfileData.Password.newPassword.length > 0) {
          formData.append(key, updatedProfileData.Password[key]);
        }
      }

      this.btnLoading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/profile`,
        {
          method: 'POST',
          headers: this.auth.headers,
          body: formData,
        }
      );
      this.btnLoading = false;
      if (!error.value) {
        this.auth.user = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async logout() {
      const { checkStatusCode } = useCheckRequestStatusCode();
      if (this.auth.headers.Authorization) {
        const { error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/logout`,

          {
            method: 'POST',
            headers: this.auth.headers,
          }
        );
        if (!error.value) {
          const { removeValue } = useLocalStorage();
          removeValue('token');
          this.auth.isLoggedIn = false;
          this.auth.headers = {};
          navigateTo('auth/signin');
        } else {
          checkStatusCode(error.value.data.statusCode);
        }
      }
    },

    async forgetPassword(payload) {
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/forgot-password`,
        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },

    async resetPassword(payload) {
      const formData = new FormData();
      for (const key in payload.data) {
        formData.append(key, payload.data[key]);
      }

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/reset-password/${payload.token}`,
        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },

    async checkActivatedAccount(payload) {
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/activate-account`,
        {
          method: 'POST',
          query: {
            token: payload.token,
            email: payload.email,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.isActivated = true;
        this.activationMessage = response.value.message;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.isActivated = false;
        this.activationMessage = error.value.data.message;

        this.serverErrors = error.value.data.errors;
      }
    },

    setHeaders(token) {
      this.auth.headers.Authorization = `Bearer ${token}`;
    },

    async clearAvatar(payload) {
      const formData = new FormData();

      formData.append('_method', 'patch');
      formData.append('clearAvatar', true);
      this.serverErrors = {};
      this.deleteLoading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/users/${payload.id}`,
        {
          method: 'POST',
          headers: this.auth.headers,
          body: formData,
        }
      );
      this.deleteLoading = false;
      if (!error.value) {
        this.auth.user = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
