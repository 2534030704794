import { useRoute } from 'vue-router';

export default function useTranslateRoutePath() {
  const route = useRoute();
  const translatedFullPath = ref(null);
  const uuidRegex = /[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/i;

  const currentPage = ref(null);
  const translatePath = (path) => {
    const pathWitoutUuId = path
      .split('/')
      .filter((element) => !uuidRegex.test(element));
    const words = pathWitoutUuId.filter(Boolean);

    if (words.length) {
      return words
        .map((word) => {
          switch (word) {
            case ' ':
              return 'الرئيسية';
            case 'default':
              return 'اللوحة الإفتراضية';
            case 'profile':
              return 'الملف الشخصي';
            case 'edit-profile':
              return 'تعديل الملف الشخصي';
            case 'customers':
              return 'العملاء';
            case 'new-customer':
              return 'إضافة عميل';
            case 'dashboard':
              return 'لوحة المعلومات';
            case 'users':
              return 'الأدمن';
            case 'show':
              return 'عرض';
            case 'edit':
              return 'تعديل';
            case 'coaches':
              return 'المرشدين';
            case 'contact-us-reasons':
              return 'أسباب تواصل معنا';
            case 'contact-us':
              return 'تواصل معنا';
            case 'new-reason':
              return 'سبب جديد';
            case 'coach-categories':
              return 'أقسام الاستشارات';
            case 'coach-levels':
              return 'أسعار المرشدين';
            case 'professional-data-settings':
              return 'إعدادات البيانات المهنية';
            case 'sessions':
              return 'الجلسات';
            case 'session-settings':
              return 'إعدادات الجلسات';
            case 'settings':
              return 'الإعدادات';
            case 'general-settings':
              return 'الإعدادات العامة';
            case 'new-session':
              return 'إضافة جلسة';
            case 'wallets':
              return 'المحافظ';
            case 'orders':
              return 'الطلبات';
            case 'order-details':
              return 'تفاصيل الطلب';
            case 'cancel-reasons':
              return 'أسباب الإلغاء';
            case 'update-coaches':
              return 'تحديث بيانات المرشدين';
            case 'faq-categories':
              return 'تصنيفات الأسئلة الشائعة';
            case 'faq':
              return 'الأسئلة الشائعة';
            case 'payments':
              return 'المدفوعات';
            case 'record-session-balance':
              return 'سجل رصيد الجلسات';
            case 'warning':
              return 'اﻹنذارات';
            case 'transactions-log':
              return 'سجل التحويلات';
            case 'pages':
              return 'الصفحات';
            case 'terms-conditions-mentor':
              return 'الشروط والأحكام للمرشد';
            case 'terms-conditions-customer':
              return 'الشروط والأحكام للعميل';
            case 'session-details':
              return 'عرض تفاصيل الجلسة';
            case 'category':
              return 'المجال';
            case 'level':
              return 'السعر';
            case 'discounts':
              return 'الخصومات';
            case 'add':
              return 'إضافة خصم';
            case 'update':
              return 'تعديل الخصم';
            case 'add-category':
              return 'إضافة قسم جديد';
            case 'rates':
              return 'التقييمات';
            case 'rates-statistics':
              return 'إحصائيات التقييم';
            case 'coaches-by-customers':
              return 'تقييم العملاء للمرشدين';
            case 'customers-by-coaches':
              return 'تقييم المرشدين للعملاء';
            case 'contact-us-list':
              return 'قائمة تواصل معنا';
            case 'roles-permissions':
              return 'الصلاحيات والادوار';
            case 'add-role':
              return 'دور جديد';
            case 'edit-role':
              return 'تعديل الدور';
            case 'details':
              return 'التفاصيل';
            case 'request-update-coach-data':
              return 'طلب تحديث بيانات المرشد';
            case 'update-coach-data':
              return 'تحديث بيانات المرشد';
            case 'reviewed':
              return 'تمت المراجعة';
            default:
              return word;
          }
        })
        .join(' / ');
    } else {
      return 'الرئيسية';
    }
  };
  translatedFullPath.value = translatePath(route.path);
  currentPage.value = translatePath(route.path).split('/')[
    translatePath(route.path).split('/').length - 1
  ];

  watch(route, (newRoute) => {
    // Do something with the new value
    translatedFullPath.value = translatePath(newRoute.path);
    currentPage.value = translatePath(newRoute.path).split('/')[
      translatePath(newRoute.path).split('/').length - 1
    ];
  });

  return {
    translatedFullPath,
    currentPage,
  };
}
