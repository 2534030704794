<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="{ 'me-sm-6': !isRTL }"
    >
    <li
      v-if="showUpdateCoachPath"
        class="text-sm breadcrumb-item text-white"
      >
        {{  `تحديث بيانات المرشدين / تحديث بيانات المرشد : ${requestUpdateDataStore.coachFullName}` }}
      </li>
      <li
        v-if="translatedFullPath.split('/').length > 1 && !showUpdateCoachPath"
        class="text-sm breadcrumb-item text-white"
      >
        {{ translatedFullPath }}
      </li>
    </ol>
    <h6
    v-if="!showUpdateCoachPath"
      class="mt-2 text-sm breadcrumb-item text-white mb-0 position-sm-absolute end-sm-0"
    >
      {{  currentPage  }}
    </h6>
    <h6
    v-if="showUpdateCoachPath"
      class="mt-2 text-sm breadcrumb-item text-white mb-0 position-sm-absolute end-sm-0"
    >
      {{ `تحديث بيانات المرشد : ${requestUpdateDataStore.coachFullName}`  }}
    </h6>
  </nav>
</template>

<script setup>
import useTranslateRoutePath from '~~/composables/useTranslateRoutePath';
const { translatedFullPath, currentPage } = useTranslateRoutePath();
import { useRoute } from 'vue-router';
import { useRequestUpdateDataStore } from '~~/stores/RequestUpdateDataStore';

defineProps({
  isRTL: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const requestUpdateDataStore = useRequestUpdateDataStore();
const showUpdateCoachPath = ref(false)
onMounted(()=>{
  if(route.path == `/request-update-coach-data/${route.params.id}/update-coach-data`){
    requestUpdateDataStore.getRequest(route.params.id).then(()=>{
      showUpdateCoachPath.value= true
    })
  }
})
watch(
  () => route.path,
  (newValue) => {
    if(route.path == `/request-update-coach-data/${route.params.id}/update-coach-data`){
    requestUpdateDataStore.getRequest(route.params.id).then(()=>{
      showUpdateCoachPath.value= true
    })
  }
  else{
    showUpdateCoachPath.value= false
  }
  },
  { deep: true }
);
</script>
