import revive_payload_client_4sVQNw7RlN from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/calwe-admin/calwe-admin/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/calwe-admin/calwe-admin/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_TzdokXk5of from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt-permissions/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/calwe-admin/calwe-admin/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import apexcharts_client_xo3MRJYPBX from "/home/runner/work/calwe-admin/calwe-admin/plugins/apexcharts.client.js";
import firebase_client_nS52P0yitD from "/home/runner/work/calwe-admin/calwe-admin/plugins/firebase.client.js";
import sentry_client_GoGQuZo4Et from "/home/runner/work/calwe-admin/calwe-admin/plugins/sentry.client.js";
import setHeaders_client_w5cjUbfQIY from "/home/runner/work/calwe-admin/calwe-admin/plugins/setHeaders.client.js";
import sweetalert2_client_W93Y1gtp8C from "/home/runner/work/calwe-admin/calwe-admin/plugins/sweetalert2.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_TzdokXk5of,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  apexcharts_client_xo3MRJYPBX,
  firebase_client_nS52P0yitD,
  sentry_client_GoGQuZo4Et,
  setHeaders_client_w5cjUbfQIY,
  sweetalert2_client_W93Y1gtp8C
]