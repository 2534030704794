<template>
  <Modal
    @save="hanldeAddOrEditFaq"
    @close="handleClose"
    :with-activation-button="true"
    :with-footer="true"
    :show-modal="showFaqModal"
    :btn-loading="faqStore.loading"
  >
    <template #activation>
      <button
        type="button"
        class="py-0 px-3 btn btn-dark btn-sm mx-2 border-radius-md"
        @click="showFaqModal = true"
      >
        +&nbsp;&nbsp; إضافة &nbsp; سؤال
      </button>
    </template>
    <template #body>
      <div class="row px-4" v-if="showFaqModal == true && faqStore.loading == false">
        <div class="d-flex flex-column align-items-start border-radius-md pe-3">
          <h2 class="text-md my-3 text-black">
            {{ objectType === "update" ? "تعديل السؤال" : "إضافة سؤال" }}
          </h2>
        </div>
        <div class="col-12 col-sm-6 mt-4">
          <SharedMultipleSelect
            id="faqCategory"
            v-model="form.faqCategory"
            label="تصنيف السؤال*"
            placeholder="اختر التصنيف"
            :options="faqStore.faqCategoriesList"
            :return-single-value="true"
            mode="single"
            :error-message="
              v$.faqCategory.$errors[0]
                ? v$.faqCategory.$errors[0].$message
                : ''
            "
          />
        </div>
        <div class="col-12 col-sm-6">
          <ArgonInput
            x-ref="priority"
            v-model="form.priority"
            type="text"
            label="ترتيب السؤال*"
            placeholder="ترتيب السؤال"
            aria-label="ترتيب السؤال"
            :error-message="
              v$.priority.$errors[0] ? v$.priority.$errors[0].$message : ''
            "
          />
        </div>
        <div class="col-12">
          <ArgonInput
            x-ref="question"
            v-model="form.question"
            type="text"
            label="السؤال*"
            placeholder="السؤال"
            aria-label="السؤال"
            :error-message="
              v$.question.$errors[0] ? v$.question.$errors[0].$message : ''
            "
          />
        </div>
        <div class="col-12 d-flex justify-content -space-around">
          <div class="col-6 col-sm-6">
            <CustomeSelect
              :model-value="form.type"
              v-model="form.type"
              :element-id="form.type"
              label="عرض السؤال"
              return-key="id"
              :options-list="filterType"
              :error-message="
                v$.type.$errors[0] ? v$.type.$errors[0].$message : ''
              "
            >
            </CustomeSelect>
          </div>
          <div class="col-6 col-sm-6 d-flex pt-5">
            <ArgonSwitch
              id="isAvailable"
              name="isAvailable"
              @change="form.isAvailable = !form.isAvailable"
              v-model="form.isAvailable"
              :checked="form.isAvailable"
            >
              <span class="font-weight-bolder text-black">
                حالة السؤال /{{ form.isAvailable ? "متاح" : "غير متاح" }}
              </span>
            </ArgonSwitch>
          </div>
        </div>
        <div class="col-12 col-sm-12 mt-2">
          <label class="text-14 text-black font-weight-bolder">الوصف*</label>

          <div
            id="editor"
            class="w-100"
            v-if="
              (objectType === 'update' && form.answer) ||
              objectType === 'create'
            "
          >
            <quill-editor
              ref="editor"
              v-model:content="form.answer"
              content-type="html"
              :options="editorOption"
            />
          </div>
          <div v-if="v$.answer.$error" class="error-message">
            {{ v$.answer.$errors[0].$message }}
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { QuillEditor } from '@vueup/vue-quill';
import { useFaqStore } from '~~/stores/FaqStore';
import { required$, num$, minLength$, maxLength$ } from '@/helpers/validators';

const faqStore = useFaqStore();
// const fetchCategoriesList = faqStore.fetchCategoriesList();
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  objectType: {
    type: String,
    default: 'create',
  },
});

const editorOption = ref({
  theme: 'snow',
  modules: {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video'],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['clean'],
      ],
      handlers: {
        // image: uploadImage,
      },
    },
  },
});

const filterType = ref([
  {
    name: 'المرشد',
    id: '1',
  },
  {
    name: 'العميل',
    id: '2',
  },
  {
    name: 'للكل',
    id: '3',
  },
]);

const serverErrors = ref({});
const form = ref({
  question: '',
  answer: '',
  priority: '',
  faqCategory: [],
  isAvailable: true,
  type: '',
});

const rules = computed(() => {
  return {
    question: {
      required$,
      minLength$: minLength$(3),
      maxLength$: maxLength$(255),
    },
    answer: {
      required$,
    },
    priority: {
      required$,
      num$,
    },
    faqCategory: {
      required$,
    },
    type: {
      required$,
    },
  };
});

const { v$ } = useCustomVulidate(rules, form, serverErrors);

// Handle action add and close
const emit = defineEmits(['handleResponse', 'close']);

const showFaqModal = ref(false);

async function hanldeAddOrEditFaq() {
  v$.value.$clearExternalResults();
  v$.value.$touch();
  if (!v$.value.$error) {
    if (props.objectType === 'update') {
      await faqStore.updateFaq(form.value).then((response) => {
        handleClose();
        if (response.success) {
          emit('handleResponse', response.message);
        }
      });
    } else {
      await faqStore.createNewFaq(form.value).then((response) => {
        handleClose();
        if (response.success) {
          emit('handleResponse', response.message);
        }
      });
    }
    serverErrors.value = faqStore.serverErrors;
  }
}

function fillForm() {
  form.value = { ...faqStore.faq };
}

function handleClose() {
  v$.value.$reset();
  form.value = {};
  showFaqModal.value = false;
  emit('close', false);
}

watch(
  () => showFaqModal.value,
  (newValue) => {
    if (newValue === true) {
      form.value = {
        faqCategory: [],
        type: filterType.value[0] ? filterType.value[0].id : '',
        answer: '',
        priority: '',
        isAvailable: true,
      };
    }
  }
);
watch(
  () => props.showModal,
  (newValue) => {
    if (newValue === true) {
      showFaqModal.value = true;
    }
  }
);
watch(
  () => faqStore.faq,
  (newValue) => {
    if (Object.keys(newValue).length > 0) {
      fillForm();
      faqStore.getCategoriesListPermissionsList();
    }
  },
  { deep: true }
);
</script>
<style>
.ql-snow .ql-editor img {
  max-width: 50% !important;
}
.ql-snow .ql-editor {
  height: 200px !important;
  overflow: auto !important;
}
</style>
