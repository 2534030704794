<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          nav-text="لوحة المعلومات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/dashboard"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/dashboard.svg"
              alt="admin-icon"
            />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_users'" class="nav-item">
        <sidenav-collapse
          nav-text="الأدمن"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/users"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/admin-icon.svg"
              alt="admin-icon"
            />
          </template>
        </sidenav-collapse>
      </li>

      <li v-can="'show_customers'" class="nav-item">
        <sidenav-collapse
          nav-text="العملاء"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/customers"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/customer-icon.svg"
              alt="admin-icon"
            />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_coaches'" class="nav-item">
        <sidenav-collapse
          nav-text="المرشدين"
          collapse-ref="/coaches"
          :active-collapse="activeCollapse"
          @update:activeCollapse="updateActiveCollapse"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/admin-icon.svg"
              alt="admin-icon"
            />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem
                to="/coaches"
                mini-icon="N"
                text="قائمة المرشدين"
              />
              <SidenavItem
                :to="{
                  path: '/coaches/update-coaches/category',
                }"
                mini-icon="S"
                text="تحديث المجال"
              />
              <SidenavItem
                :to="{
                  path: '/coaches/update-coaches/level',
                }"
                mini-icon="S"
                text="تحديث السعر"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_coaches'" class="nav-item">
        <sidenav-collapse
          nav-text="طلب تحديث بيانات المرشد"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/request-update-coach-data"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/update-coach-data-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_sessions'" class="nav-item">
        <sidenav-collapse
          nav-text="الجلسات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/sessions"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/sessions-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_orders'" class="nav-item">
        <sidenav-collapse
          nav-text="الطلبات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/orders"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/order-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>

      <li v-can="'show_payouts'" class="nav-item">
        <sidenav-collapse
          nav-text="المدفوعات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/payments"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/credit-card.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_wallets'" class="nav-item">
        <sidenav-collapse
          nav-text="المحافظ"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/wallets"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/wallet-money-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>

      <li v-can="'show_transactions'" class="nav-item">
        <sidenav-collapse
          nav-text="سجل التحويلات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/transactions-log"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/money-transactions-icon.svg"
            />
          </template>
        </sidenav-collapse>
      </li>

      <li v-can="'show_rates'" class="nav-item">
        <sidenav-collapse
          nav-text="التقييمات"
          collapse-ref="/rates/coaches-by-customers"
          :active-collapse="activeCollapse"
          @update:activeCollapse="updateActiveCollapse"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/star-icon.svg"
              alt="warning-icon"
            />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem
                to="/rates/coaches-by-customers"
                mini-icon="S"
                text="تقييم المرشدين"
              />
              <SidenavItem
                to="/rates/customers-by-coaches"
                mini-icon="N"
                text="تقييم العملاء"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_rates'" class="nav-item">
        <sidenav-collapse
          nav-text="إحصائيات التقييم"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/rates-statistics"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/rates-statistics.svg"
            />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_coach_levels'" class="nav-item">
        <sidenav-collapse
          nav-text="أسعار المرشدين"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/coach-levels"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/coach-levels-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_coach_categories'" class="nav-item">
        <sidenav-collapse
          nav-text="أقسام الاستشارات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/coach-categories"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/coach-categories-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_faq_categories'" class="nav-item">
        <sidenav-collapse
          nav-text="تصنيفات الأسئلة الشائعة"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/faq-categories"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/FAQ-category-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_faqs'" class="nav-item">
        <sidenav-collapse
          nav-text="الأسئلة الشائعة"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/faq"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/faq-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>

      <li v-can="'show_discounts'" class="nav-item">
        <sidenav-collapse
          nav-text="الخصومات"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/discounts"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/discount-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-can="'show_cancel_reasons'" class="nav-item">
        <sidenav-collapse
          nav-text="أسباب الإلغاء"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/cancel-reasons"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/cancellations-icon.svg" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="الصفحات"
          collapse-ref="//pages/terms-conditions"
          :active-collapse="activeCollapse"
          @update:activeCollapse="updateActiveCollapse"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/terms-and-conditions.svg" />
          </template>
          <template #list>
            <ul v-can="'show_pages'" class="nav ms-4">
              <li class="nav-item">
              <SidenavItem
                to="/pages/terms-conditions-mentor"
                mini-icon="S"
                text="الشروط والأحكام للمرشد"
              />
            </li>
            <li class="nav-item">
              <SidenavItem
                to="/pages/terms-conditions-customer"
                mini-icon="S"
                text="الشروط والأحكام للعميل"
              />
            </li>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="تواصل معنا"
          collapse-ref="/contact-us/contact-us-list"
          :active-collapse="activeCollapse"
          @update:activeCollapse="updateActiveCollapse"
        >
          <template #icon>
            <img
              class="ms-2"
              src="~/assets/img/icons/side_nav/contact-us-reason-icon.svg"
            />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem
                v-can="'show_contact_us'"
                to="/contact-us/contact-us-list"
                mini-icon="N"
                text="قائمة تواصل معنا"
              />
              <SidenavItem
                v-can="'show_contact_us_reasons'"
                to="/contact-us/contact-us-reasons"
                mini-icon="S"
                text="أسباب تواصل معنا"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="اﻹنذارات"
          collapse-ref="/warning/customers"
          :active-collapse="activeCollapse"
          @update:activeCollapse="updateActiveCollapse"
        >
          <template #icon>
            <img
              src="~/assets/img/icons/side_nav/warning-icon.svg"
              alt="warning-icon"
            />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem
                v-can="'show_customer_warnings'"
                to="/warning/customers"
                mini-icon="N"
                text="العملاء"
              />
              <SidenavItem
                v-can="'show_coach_warnings'"
                to="/warning/coaches"
                mini-icon="S"
                text="المرشدين"
              />
              <SidenavItem
                to="/warning/reviewed"
                mini-icon="S"
                text="تمت مراجعته"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="الإعدادات"
          collapse-ref="/settings/general-settings"
          :active-collapse="activeCollapse"
          @update:activeCollapse="updateActiveCollapse"
        >
          <template #icon>
            <img src="~/assets/img/icons/side_nav/settings-icon.svg" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem
                v-can="'show_settings'"
                to="/settings/general-settings"
                mini-icon="S"
                text="الإعدادات العامة"
              />
              <SidenavItem
                v-can="'show_sessions_settings'"
                to="/settings/session-settings"
                mini-icon="S"
                text="إعدادات الجلسات"
              />
              <SidenavItem
                v-can="'show_professional_data_settings'"
                to="/settings/professional-data-settings"
                mini-icon="S"
                text="إعدادات البيانات المهنية"
              />
              <SidenavItem
                v-can="'show_permissions'"
                to="/settings/roles-permissions"
                mini-icon="S"
                text="الصلاحيات والأدوار"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script setup>
import SidenavCollapse from './SidenavCollapse.vue';
import SidenavCollapseItem from './SidenavCollapseItem.vue';
import SidenavItem from './SidenavItem.vue';
import { useNavStore } from '~~/stores/NavStore';
import { useAuthStore } from '~~/stores/AuthStore';
const authStore = useAuthStore();

const navStore = useNavStore();

const activeCollapse = ref('');

const updateActiveCollapse = (collapseRef) => {
  activeCollapse.value = collapseRef;
};

const sidenavType = computed(() => {
  return navStore.sidenavType === 'bg-white' ? 'text-dark' : 'text-white';
});
const getRoute = computed(() => {
  const route = useRoute();
  return route.path.split('/')[1];
});
const permissions = computed(() => {
  return authStore.auth.user.permissions;
});
defineProps({
  isRTL: {
    type: Boolean,
    default: false,
  },
});
</script>
