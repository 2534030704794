<template>
  <div class="card card-outlined mb-3">
    <div class="d-flex align-items-center mt-3 mx-2">
      <div
        class="icon icon-shape icon-sm shadow text-center border-radius-sm d-flex justify-content-center align-items-center bg-gradient-success p-4 me-2"
      >
        <img v-if="isCountryStatistic == false" src="~/assets/img/icons/dashboard/user-group-icon.svg" alt="group-icon" />
        <img v-else-if="statisticsType == 'topRecordingCountriesCustomers'" src="~/assets/img/icons/dashboard/customer-icon.svg" alt="group-icon" />
        <img v-else-if="statisticsType == 'topRecordingCountriesCoaches'" src="~/assets/img/icons/dashboard/coach-icon.svg" alt="group-icon" />
      </div>
      <div class="d-flex mx-4">
        <h5 class="mb-0 text-center font-weight-bolder">{{ title }}</h5>
      </div>
    </div>
    <div class="p-3 pt-0 mt-2 mx-4 d-flex text-center statistics-card">
      <div v-if="isCountryStatistic == false" class="row mb-3">
        <div v-for="statistic in statistics" :key="statistic.value" class="col-lg-4 col-md-6 col-sm-8 col-8 mt-3 justify-content-center">
          <div class="statistics-card bg-statistics-card border-radius-lg px-2">
            <div class="card-body d-flex flex-column justify-content-around align-items-center">
              <p class="text-md font-weight-bolder">
               {{ statistic.objectValue }}
              </p>
              <p class="text-sm font-weight-bold d-block">
                {{ statistic.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row mb-3">
        <div v-for="statistic in value" :key="statistic" class="col-lg-4 col-md-6 col-sm-8 col-8 mt-3 justify-content-center">
          <div class="statistics-card bg-statistics-card border-radius-lg px-2">
            <div class="card-body d-flex flex-column justify-content-around align-items-center">
              <p class="text-md font-weight-bolder">
               {{ statistic.count }}
              </p>
              <p class="text-sm font-weight-bold d-block">
                {{ statistic.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  value: {
    type: [Object, Array],
  },
  statisticsType: {
    type: String,
    default: '',
  },
  isCountryStatistic: {
    type: Boolean,
    default: false,
  },
})

// Convert the object to an array of header objects
const headers = reactive([
  { text: 'المسجلين اليوم', value: 'created_today' },
  { text: 'المسجلين الجدد اخر 7 ايام', value: 'latest' },
  { text: 'الفعالين اليوم', value: 'activated_today' },
  { text: 'اجمالي المسجلين من البداية', value: 'total_registered_from_start' },
  { text: 'لديهم جلسات', value: 'has_sessions' }
])

// Map over headers and append values from the object
const statistics = reactive(
  headers.map(header => ({
    ...header,
    objectValue: props.value[header.value] 
  }))
)
</script>
<style lang="scss" scoped>
.bg-statistics-card {
  background-color: #f6f6f6;
  border: 1px solid #dbdbdb;
}
</style>