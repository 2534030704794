<template>
  <!-- Button trigger modal -->
  <slot v-if="withActivationButton" name="activation" />
  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="false"
    ref="modalEle"
  >
    <div
      class="h-100 modal-dialog d-flex align-items-center justify-content-center1"
      :class="`modal-${size}`"
    >
      <div class="modal-content" :class="{ 'semi-rounded': semiRounded }">
        <button
          v-if="closeable"
          type="button"
          class="btn-close text-dark position-absolute start-2 top-2 z-index-1 text-xs mt-2 ms-2"
          aria-label="Close"
          @click="$emit('close')"
          name="button"
        >
          <!-- <i class="fas fa-times"></i> -->
        </button>
        <div v-if="withHeader" class="modal-header">
          <h5 v-if="title" class="modal-title" id="staticBackdropLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <slot v-if="withFooter" name="footer">
          <div class="modal-footer mt-4">
            <div class="col-12 col-sm-6">
              <div class="mt-3 mb-3 d-flex flex-row-reverse">
                <button
                  @click="$emit('close')"
                  class="mb-0 btn btn-outline-dark btn-sm mx-2"
                  type="button"
                  name="button"
                >
                  إلغاء
                </button>

                <ArgonButton
                  :loading="btnLoading"
                  :disabled="btnLoading"
                  class="px-5 mb-0 btn btn-dark btn-sm"
                  @click.prevent="$emit('save')"
                  >حفظ</ArgonButton
                >
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Modal } from 'bootstrap';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  showModal: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'lg',
  },
  closeable: {
    type: Boolean,
    default: false,
  },
  withActivationButton: {
    type: Boolean,
    default: false,
  },
  withHeader: {
    type: Boolean,
    default: false,
  },
  withFooter: {
    type: Boolean,
    default: false,
  },
  semiRounded: {
    type: Boolean,
    default: false,
  },
  btnLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['save', 'close']);

const modalEle = ref(null);
let modalObj = null;

function _show() {
  modalObj.show();
}
function _hide() {
  modalObj.hide();
}
defineExpose({ show: _show, hide: _hide });

onMounted(() => {
  modalObj = new Modal(modalEle.value);
});

watch(
  () => props.showModal,
  (newValue) => {
    if (newValue) {
      modalObj.show();
    } else {
      modalObj.hide();
    }
  },
  { deep: true }
);
</script>
