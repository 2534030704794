<template>
  <NuxtLink
    :data-bs-toggle="collapse ? 'collapse' : ''"
    :aria-expanded="isExpanded"
    class="nav-link"
    :to="collapseRef"
    v-bind="$attrs"
    @click="toggleCollapse"
  >
    <div
      class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text">{{ navText }}</span>
  </NuxtLink>
  <div :class="isExpanded ? 'collapse show' : 'collapse'">
    <slot name="list"></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  collapseRef: {
    type: String,
    default: () => null,
  },
  navText: {
    type: String,
    required: true,
  },
  collapse: {
    type: Boolean,
    default: true,
  },
  activeCollapse: {
    type: String,
    default: '',
  }
});

const emit = defineEmits(['update:activeCollapse']);

const isExpanded = ref(false);

watch(
  () => props.activeCollapse,
  (newVal) => {
    isExpanded.value = newVal === props.collapseRef;
  }
);

const toggleCollapse = () => {
  emit('update:activeCollapse', isExpanded.value ? '' : props.collapseRef);
};
</script>