import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';


export const useFaqCategoriestStore = defineStore({
  id: 'faqCategories',
  state: () => ({
    faqCategory: null,
    faqCategories: {},
    serverErrors: {},
    filters: null,
    loading: false,
  }),
  actions: {
    async fetch(filter) {
      const authStore = useAuthStore();
      this.loading = true;
      this.filters = filter;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faq-categories`,
        {
          method: 'GET',
          params: {
            ...filter,
          },
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.faqCategories = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async createNewFaqCategory(faqCategory) {
      const authStore = useAuthStore();
      const formData = new FormData();
      for (const key in faqCategory) {
        formData.append(key, faqCategory[key]);
      }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faq-categories`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch(this.filters);
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },

    async getFaqCategory(FaqCategoryId) {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faq-categories/${FaqCategoryId}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.faqCategory = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
      }
    },

    async updateFaqCategory(payload) {
      const authStore = useAuthStore();
      this.type = payload.type;
      const formData = new FormData();
      formData.append('_method', 'patch');
      for (const key in payload) {
        if (key !== 'id') {
          formData.append(key, payload[key]);
        }
      }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faq-categories/${payload.id}`,
        {
          method: 'POST',
          body: formData,
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch(this.filters);
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async deleteFaqCategories(faqCategoryId) {
      const authStore = useAuthStore();
      this.loading = true;

      const { data: response, error } = await useFetch(
        `${
          useRuntimeConfig().public.baseUrl
        }/faq-categories?ids[]=${faqCategoryId}`,
        {
          method: 'DELETE',
          headers: authStore.auth.headers,
          body: {
            ids: faqCategoryId,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch(this.filters);
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
