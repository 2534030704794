import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';
const { checkStatusCode } = useCheckRequestStatusCode();

export const useRequestUpdateDataStore = defineStore({
  id: 'requestUpdateData',
  state: () => ({
    requests: {},
    request: {},
    serverErrors: {},
    loading: false,
    loadingShowPage:false,
    filters: null,
  }), getters: {
  coachFullName(state) {
    return state?.request?.coachFullname || '';
  }
  },
  actions: {
    async fetch(payload) {
      const authStore = useAuthStore();
      this.filters = payload;
      const customFilter = {};
      if (payload.filters) {
        for (const key in payload.filters) {
          if (
            payload.filters[key] ||
            (typeof payload.filters[key] === 'string' &&
              payload.filters[key].length > 0)
          ) {
            customFilter[`filter[${key}]`] = payload.filters[key];
          }
        }

      }
      const { filters ,...paginationFilters } = payload;
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coach-update-requests`,
        {
          method: 'GET',
          params: {
            ...customFilter,
            ...paginationFilters,
          },
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.requests = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async update(payload) {
      const authStore = useAuthStore();
      const formData = new FormData();
      formData.append('_method', 'patch');
      for (const key in payload.data) {
        if (this.request[key] !== payload.data[key]) {
          formData.append(key, payload.data[key]);
        }
      }
      this.loadingShowPage = true;
      const { error, data: response } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coach-update-requests/${payload.id}`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loadingShowPage = false;
      if (!error.value) {
        this.getRequest(payload.id);
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    
    async getRequest(id) {
      const authStore = useAuthStore();
      this.loadingShowPage = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coach-update-requests/${id}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      this.loadingShowPage = false;
      if (!error.value) {
        this.request = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
   getCoachName(id){
   if(this.requests.requests){
     return this.requests.requests.find((coach) => coach.id === id).coachFullName
   }
   else{
     return ''
   }
   },
   async downloadFile(fileUrl) {
    const authStore = useAuthStore();
    const fileNameWithExtension =
      fileUrl.split('/')[fileUrl.split('/').length - 1];
    const { data: response, error } = await useFetch(
      `${useRuntimeConfig().public.baseUrl}/download`,
      {
        method: 'GET',
        headers: authStore.auth.headers,
        responseType: 'blob',
        params: {
          url: fileUrl,
        },
      }
    );
    if (!error.value) {
      const url = window.URL.createObjectURL(new Blob([response.value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileNameWithExtension}`); // Set the desired file name
      document.body.appendChild(link);
      link.click();
    } else {
      // handle error
    }
  },
  },
});
