import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
import { useAuthStore } from "~~/stores/AuthStore";
const { checkStatusCode } = useCheckRequestStatusCode();


export const useProfessionalDataStore = defineStore({
  id: "ProfessionalData",
  state: () => ({
    languages: {},
    qualifications: {},
    degrees:{},
    serverErrors: {},
    loading: false,
  }),

  actions: {
    // language actions
    async fetchLanguages() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/languages`,
        {
          method: "GET",
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.languages = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async storeLanguages(languageData) {
      const authStore = useAuthStore();
      this.loading = true;
      const formData = new FormData();

      if(languageData.name != '')
        formData.append("name", languageData.name);
      if(languageData.priority != '')
        formData.append("priority", languageData.priority);

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/languages`,
        {
          method: "POST",
          headers: authStore.auth.headers,
          body: formData ,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.serverErrors = {};
        this.fetchLanguages();
        return response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async updateLanguages(payload) {
      const authStore = useAuthStore();
      const formData = new FormData();
      formData.append("_method", "patch");
      if(payload.data.name != '')
        formData.append("name", payload.data.name);
      if(payload.data.priority != '')
        formData.append("priority", payload.data.priority);


      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/languages/${payload.id}`,
        {
          method: "POST",
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.serverErrors = {};
        this.fetchLanguages();
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async deleteLanguages(languageId) {
      const authStore = useAuthStore();
      this.loading = true;

      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/languages/?ids[]=${languageId}`,
        {
          method: "DELETE",
          headers: authStore.auth.headers,
          body: {
            ids: languageId,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetchLanguages();
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    // qualification actions
    async fetchQualifications() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/qualifications`,
        {
          method: "GET",
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.qualifications = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async storeQualifications(qualificationData) {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/qualifications`,
        {
          method: "POST",
          headers: authStore.auth.headers,
          body: { 
            qualified: qualificationData.name,
            priority: qualificationData.priority
           },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetchQualifications();
        this.serverErrors = {};
        return response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async updateQualifications(payload) {
      const authStore = useAuthStore();
      const formData = new FormData();
      formData.append("_method", "patch");

      if(payload.data.name != '')
        formData.append("qualified", payload.data.name);
      if(payload.data.priority != '')
        formData.append("priority", payload.data.priority);

      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/qualifications/${payload.id}`,
        {
          method: "POST",
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.serverErrors = {};
        this.fetchQualifications();
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async deleteQualification(qualificationId) {
      const authStore = useAuthStore();
      this.loading = true;

      const { error } = await useFetch(
        `${
          useRuntimeConfig().public.baseUrl
        }/qualifications/?ids[]=${qualificationId}`,
        {
          method: "DELETE",
          headers: authStore.auth.headers,
          body: {
            ids: qualificationId,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetchQualifications();
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    // degree actions
    async fetchDegrees() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/degrees`,
        {
          method: "GET",
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.degrees = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async storeDegrees(degreeData) {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/degrees`,
        {
          method: "POST",
          headers: authStore.auth.headers,
          body: { 
            degree: degreeData.name,
            priority: degreeData.priority
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.serverErrors = {};
        this.fetchDegrees();
        return response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async updateDegrees(payload) {
      const authStore = useAuthStore();
      const formData = new FormData();
      formData.append("_method", "patch");

      if(payload.data.name != '')
        formData.append("degree", payload.data.name);
      if(payload.data.priority != '')
        formData.append("priority", payload.data.priority);

      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/degrees/${payload.id}`,
        {
          method: "POST",
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.serverErrors = {};
        this.fetchDegrees();
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async deleteDegree(degreeId) {
      const authStore = useAuthStore();
      this.loading = true;

      const { error } = await useFetch(
        `${
          useRuntimeConfig().public.baseUrl
        }/degrees/?ids[]=${degreeId}`,
        {
          method: "DELETE",
          headers: authStore.auth.headers,
          body: {
            ids: degreeId,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetchDegrees();
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
