import { defineNuxtPlugin } from '#app';
import { useAuthStore } from '@/stores/AuthStore';

export default defineNuxtPlugin(async (nuxtApp) => {
  if (process.client) {
    // const route = useRoute();
    const { getValue } = useLocalStorage();
    const token = getValue('token');
    const authStore = useAuthStore(nuxtApp.$pinia);
    if (!authStore.auth.isLoggedIn && token && token.length > 0) {
      authStore.setHeaders(token);
      await authStore.getProfile();
    
    }
  }
});
