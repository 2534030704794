<template>
  <div
    id="app-layout"
    :class="isCollapsed ? 'g-sidenav-hidden' : 'g-sidenav-show'"
  >
    <div class="hero-container position-absolute w-100 bg-dark" :class="route.name == 'payments-id-record-session-balance' ? 'extend-hero-container' : ''" />
    <SidenavRTL />
    <main class="main-content position-relative max-height-vh-100 h-100">
      <NavbarRTL />
      <slot />
    </main>
  </div>
</template>

<script setup>
import SidenavRTL from '@/examples/Sidenav/RTL.vue';
import NavbarRTL from '@/examples/Navbar/RTL.vue';
import { useNavStore } from '~~/stores/NavStore';

const route = useRoute();
const navStore = useNavStore();
const isCollapsed = computed(() => {
  return navStore.isSidenavCollapsed;
});
useHead({
  bodyAttrs: {
    class: 'bg-gray-100 rtl',
  },
});
</script>
<style lang="scss" scoped>
.hero-container{
  min-height: 300px;
  @media (max-width:1000px) {
    min-height: 400px;
  }
  @media (max-width:770px) {
    min-height: 500px;
  }
}
.extend-hero-container{
  min-height: 450px;
}
</style>