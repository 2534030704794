<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :name="name"
      :checked="checked"
    />
    <label :for="id" class="custom-control-label" :class="$attrs.class">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'ArgonCheckbox',
  props: {
    id: {
      validator: (value) => {
        return typeof value === 'string' || typeof value === 'number';
      },
      default: '',
    },
    name: {
      type: String || Number,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleCheck() {
      // Emit an event to notify the parent component about the checkbox state change
      this.$emit('change');
    },
  },
};
</script>
