<template>
  <div class="form-group mt-4 mb-0">
    <form>
      <div>
        <label v-if="label" :for="id" class="custom-control-label float-end">
          {{ label }}
        </label>
        <input
        :id="id"
        :type="type"
        class="px-2 py-1 w-100"
        :name="name"
        :disabled="disabled"
        :value="tempItem.name"
        :placeholder="placeholder"
        @input="tempItem.name = $event.target.value"
        @keydown.enter="$emit('insert', tempItem)"
        />
        <div v-if="v$.name.$errors[0] || serverError.qualified || serverError.degree || serverError.name" class="error-message position-absolute">
        {{ serverError.name && id == 'language' ? serverError.name : '' }}
        {{ serverError.qualified && id == 'qualification' ? serverError.qualified : '' }}
        {{ serverError.degree && id == 'degree' ? serverError.degree : '' }}
      </div>
      </div>
      <div class="">
        <label :for="id" class="custom-control-label float-end">
          اﻷولوية
        </label>
        <input
        :id="id"
        type="number"
        class="px-2 py-1 w-80"
        :name="name"
        :disabled="disabled"
        :value="tempItem.priority"
        placeholder="اﻷولوية"
        @input="tempItem.priority = $event.target.value"
        @keydown.enter="$emit('insert', tempItem)"
      />
      <div v-if="v$.priority.$errors[0] || serverError.priority" class="error-message position-absolute">
        {{ v$.priority.$errors[0] ? v$.priority.$errors[0].$message : '' }}
      </div>
    </div>
    <div class="d-flex align-items-end">
      <button
      :disabled="disabled"
      type="button"
      class="btn btn-dark btn-sm border-radius-sm py-2 mb-0 mt-4"
      @click="showAdd ? handleAdd() : handleUpdate()"
      >
      {{ showAdd ? "إضافة" : "تعديل" }}
    </button>
  </div>
</form>
    <div v-for="item in items" :key="item" class="pill">
      <div
        class="d-inline ms-1"
        @click="
          tempItem.name = item[returnKey];
          tempItem.priority = item.priority;
          showAdd = false;
          tempId = item.id;
        "
      >
        {{ item[returnKey] }}
      </div>
      <img
      v-if="!disabled"
        src="~/assets/img/icons/share/close-icon.svg"
        alt="close icon"
        @click="$emit('delete', { itemId: item.id, methodName: id })"
      />
    </div>
  </div>
</template>

<script setup>
import {
  number$, required$
} from '@/helpers/validators';
import { useProfessionalDataStore } from '~~/stores/ProfessionalDataStore';
const professionalDataStore = useProfessionalDataStore();

const props = defineProps({
 
    items: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    icon: {
      type: String,
      default: '',
    },
    iconDir: {
      type: String,
      default: 'left',
    },
    name: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    returnKey: {
      type: String,
      default: '',
    },
  })

const emits = defineEmits(['insert' ,'edit']);
  
const showAdd = ref(true)
const tempItem = ref({
        name: '',
        priority: ''
      })
const tempId = ref('')

const rules = computed(() => {
  return {
    priority: {
      number$,
    },
    name: {
      required$
    }
  }
})

const serverError = ref({});
const { v$ } = useCustomVulidate(rules, tempItem, serverError);
  
function handleAdd() {
    v$.value.$clearExternalResults();
    v$.value.$validate();
    
    if (!v$.value.$error) {
      emits('insert', tempItem.value);
    }
}
function handleUpdate() {
    v$.value.$clearExternalResults();
    v$.value.$validate();

    if (!v$.value.$error) {
      emits('edit', { id: tempId.value, data: tempItem.value });

      if(serverError.value == {}){
       showAdd.value = true;
     
       }
      
    }
  }

watch(() => tempItem.value.priority, () => {
 
  if(tempItem.value.priority == '' && tempItem.value.name == '') {
    showAdd.value = true
  }
})

watch(() => tempItem.value.name, () => {

  if(tempItem.value.priority == '' && tempItem.value.name == '') {
    showAdd.value = true
  }
})

watch(() => professionalDataStore.serverErrors, (newValue) => {
  if(Object.keys(newValue).length === 0){
      tempItem.value.priority = '';
      tempItem.value.name = '';
      v$.value.$reset();
  }
  else
    serverError.value = professionalDataStore.serverErrors
})
  
</script>
<style scoped>
form {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #cbcbcb;
  padding: 20px;
}

input {
  border-radius: 6px;
  border: solid 1px #cbcbcb;
  background-color: #fff;
}
input:focus {
  outline: none;
  background-color: transparent;
  box-shadow: none;
}
.pill {
  display: inline-block;
  margin: 10px 5px 0 0;
  cursor: pointer;
  padding: 6px 12px;
  box-shadow: 0 2px 4px 0 rgba(44, 39, 56, 0.08);
  background-color: #292929;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
img {
  width: 14px;
}
</style>
