import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';


export const useFaqStore = defineStore({
  id: 'faq',
  state: () => ({
    faq: null,
    faqs: {},
    faqCategoriesList: [],
    serverErrors: {},
    filters: {},
    loading: false,
  }),
  actions: {
    async fetch(payload) {
      this.filters = payload;
      const authStore = useAuthStore();
      const customFilter = {};
      if (payload.filters) {
        for (const key in payload.filters) {
          if( payload.filters[key] ||
            (typeof payload.filters[key] === 'string' &&
              payload.filters[key].length > 0)){
          customFilter[`filter[${key}]`] = payload.filters[key];
        }
        }
      }
      this.loading = true;
      const { filters, ...paginationFilters } = payload;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faqs`,
        {
          method: 'GET',
          params: {
            ...paginationFilters,
            ...customFilter
          },
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.faqs = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async fetchCategoriesList() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faq-categories`,
        {
          method: 'GET',
          params: {
            list: true,
          },
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.faqCategoriesList = response.value.data.faqCategories;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
    async getCategoriesListPermissionsList() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/list/faq_categories`,
        {
          method: 'GET',
          params: {
            list: true,
          },
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.faqCategoriesList = response.value.data.faqCategories;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async createNewFaq(faq) {
      const authStore = useAuthStore();
      const formData = new FormData();
      for (const key in faq) {
        if(key == 'faqCategory'){
          formData.append('faqCategoryId', faq[key]);
        }else{
          formData.append(key, faq[key]);
        }
      }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faqs`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch(this.filters);
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },

    async getFaqDetails(FaqId) {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faqs/${FaqId}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.faq = response.value.data;
      } else {
        checkStatusCode(error.value.data.statusCode);
      }
    },

    async updateFaq(payload) {
      const authStore = useAuthStore();
      this.type = payload.type;
      const formData = new FormData();
      formData.append('_method', 'patch');
      for (const key in payload) {
        if(key == 'faqCategory'){
          if(this.faq[key]['id'] !== payload[key] && this.faq[key]['id'] !== payload[key]['id']){
            formData.append('faqCategoryId', payload[key]);
          }
        }
        else if (key !== 'id') {
          formData.append(key, payload[key]);
      }
    }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faqs/${payload.id}`,
        {
          method: 'POST',
          body: formData,
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch(this.filters);
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },

    async deleteFaqs(faqId) {
      const authStore = useAuthStore();
      this.loading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/faqs?ids[]=${faqId}`,
        {
          method: 'DELETE',
          headers: authStore.auth.headers,
          body: {
            ids: faqId,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch(this.filters);
        return response.value;
      } else {
        checkStatusCode(error.value.data.statusCode);
        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
