export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Calwe"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/callwe_fav.png"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"},{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"}],"style":[],"script":[{"type":"text/javascript","src":"https://kit.fontawesome.com/42d5adcbca.js","async":true},{"type":"text/javascript","src":"https://buttons.github.io/buttons.js","async":true}],"noscript":[],"title":"Calwe","htmlAttrs":{"lang":"ar","dir":"rtl"}}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'