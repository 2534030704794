export default defineNuxtRouteMiddleware((from, to) => {
  const { getValue } = useLocalStorage();
  const token = getValue('token');
  if (
    (!token || token.length === 0) &&
    to.path !== undefined &&
    !to.path.includes('auth-')
  ) {
    to.path = 'auth-signin';

    return navigateTo({ path: '/auth/signin' });
  } else if (
    to.path !== undefined &&
    to.path.includes('auth-') &&
    token &&
    token.length > 0
  ) {
    to.path = 'index';
    return navigateTo({ path: '/' });
  }
});