<template>
  <Modal
    @save="hanldeAddOrEditFaqCategory"
    @close="hanldeClose"
    :with-activation-button="true"
    :with-footer="true"
    :show-modal="showFaqCatogryModal"
    :btn-loading="faqCategoriestStore.loading"
  >
    <template #activation>
      <button
        type="button"
        class="py-0 px-3 btn btn-dark btn-sm mx-2 border-radius-md"
        @click="showFaqCatogryModal = true"
      >
        +&nbsp;&nbsp; إضافة &nbsp; تصنيف
      </button>
    </template>
    <template #body>
      <div class="row px-4" v-if="showFaqCatogryModal == true">
        <div class="d-flex flex-column align-items-start border-radius-md pe-3">
          <h2 class="text-md my-3 text-black">
            {{ objectType === "update" ? "تعديل التصنيف" : "إضافة تصنيف" }}
          </h2>
        </div>
        <div class="col-12 col-sm-6">
          <ArgonInput
            id="name"
            v-model="form.name"
            type="text"
            label="اسم التصنيف"
            placeholder="اسم التصنيف"
            aria-label="اسم التصنيف"
            :error-message="
              v$.name.$errors[0] ? v$.name.$errors[0].$message : ''
            "
          />
        </div>
        <div class="col-12 col-sm-6">
          <ArgonInput
            x-ref="priority"
            v-model="form.priority"
            type="text"
            label="ترتيب التصنيف"
            placeholder="ترتيب التصنيف"
            aria-label="ترتيب التصنيف"
            :error-message="
              v$.priority.$errors[0] ? v$.priority.$errors[0].$message : ''
            "
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 d-flex font-weight-bolder">
          <span class="ms-2 text-14 text-black">الحالة :</span>
          <ArgonSwitch
            id="isAvailable"
            name="isAvailable"
            @change="form.isAvailable = !form.isAvailable"
            v-model="form.isAvailable"
            :checked="form.isAvailable"
          >
            <span class="font-weight-bolder text-black">{{form.isAvailable ? 'متاح' : 'غير متاح'}}</span>
          </ArgonSwitch>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { useFaqCategoriestStore } from '~~/stores/FaqCategoriesStore';
import { required$, num$, alpha$, minLength$, maxLength$ } from '@/helpers/validators';

const faqCategoriestStore = useFaqCategoriestStore();
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  objectType: {
    type: String,
    default: 'create',
  },
});

// Validate data
const serverErrors = ref({});
const form = ref({
  name: '',
  priority: '',
  isAvailable: true,
});
const rules = computed(() => {
  return {
    name: {
      required$,
      alpha$,
      minLength$: minLength$(3),
      maxLength$: maxLength$(255),
    },
    priority: {
      required$,
      num$,
    },
  };
});

const { v$ } = useCustomVulidate(rules, form, serverErrors);

// Handle action add and close
const emit = defineEmits(['handleResponse', 'close']);

const showFaqCatogryModal = ref(false);

async function hanldeAddOrEditFaqCategory() {
  v$.value.$clearExternalResults();
  v$.value.$touch();
  if (!v$.value.$error) {
    if (props.objectType === 'update') {
      await faqCategoriestStore
        .updateFaqCategory(form.value)
        .then((response) => {
          hanldeClose();
          emit('handleResponse', response.message);
        });
    } else {
      await faqCategoriestStore
        .createNewFaqCategory(form.value)
        .then((response) => {
          hanldeClose();
          emit('handleResponse', response.message);
        });
    }
    serverErrors.value = faqCategoriestStore.serverErrors;
  }
}

function fillForm() {
  form.value = { ...faqCategoriestStore.faqCategory };
}

function hanldeClose() {
  v$.value.$reset();
  form.value = {
    name: '',
    priority: '',
    isAvailable: true,
  };
  showFaqCatogryModal.value = false;
  emit('close', false);
}

watch(
  () => props.showModal,
  (newValue) => {
    if (newValue === true) {
      showFaqCatogryModal.value = true;
    }
  }
);
watch(
  () => faqCategoriestStore.faqCategory,
  (newValue) => {
    if (Object.keys(newValue).length > 0) {
      fillForm();
    }
  },
  { deep: true }
);
</script>
