<template>
  <label v-if="label" class="mt-4">{{ label }}</label>
  <select
    :id="props.elementId"
    class="form-control"
    :class="getClasses(error, errorMessage)"
    :name="props.elementId"
    @change="$emit('update:modelValue', $event.target.value)"
  >
    <option
      v-for="(option, i) in optionsList"
      :selected="modelValue == option[returnKey]"
      :key="i"
      :value="option[returnKey]"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script setup>
// eslint-disable-next-line import/no-named-as-default
import Choices from 'choices.js';

import { useAuthStore } from '~~/stores/AuthStore';
const authStore = useAuthStore();

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  optionsList: {
    type: Array,
    default: () => [],
  },
  selectedItem: {
    type: String,
    default: '',
  },
  elementId: {
    type: String,
    default: '',
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  returnKey: {
    type: String,
    default: 'name',
  },
});

if (document.getElementById(props.elementId)) {
  const element = document.getElementById(props.elementId);
  // eslint-disable-next-line no-new
  new Choices(element, {
    searchEnabled: true,
  });
}

const getClasses = (error, errorMessage) => {
  let isValidValue;

  // eslint-disable-next-line prefer-const

  if (error || (errorMessage && errorMessage.length)) {
    isValidValue = 'is-invalid';
  } else {
    isValidValue = '';
  }

  return `${isValidValue}`;
};
</script>
