import { default as analyticsiXsrLVkyprMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/applications/analytics.vue?macro=true";
import { default as calendarYLQONM3bY2Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/applications/calendar.vue?macro=true";
import { default as data_45tablesZmWakAjO2fMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/applications/data-tables.vue?macro=true";
import { default as wizardzFsUKh6qtAMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/applications/wizard.vue?macro=true";
import { default as indexqJC0gqqwl4Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/auth/activate-account/[token]/[email]/index.vue?macro=true";
import { default as forget_45passwordarq6BsqJReMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/auth/forget-password.vue?macro=true";
import { default as indexF7eTnYZ0eIMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/auth/reset-password/[token]/index.vue?macro=true";
import { default as signinzZgzYpEAMnMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/auth/signin.vue?macro=true";
import { default as error404CXAHsCMoIVMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/error/error404.vue?macro=true";
import { default as error500p3FM3vors7Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/error/error500.vue?macro=true";
import { default as basicgs5DGJoW7kMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/lock/basic.vue?macro=true";
import { default as cover0ItFFqMtUFMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/lock/cover.vue?macro=true";
import { default as illustration8gRBh9PtgFMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/lock/illustration.vue?macro=true";
import { default as basicClWKqkOuoxMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/reset/basic.vue?macro=true";
import { default as coverdLrZlV3LlOMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/reset/cover.vue?macro=true";
import { default as illustrationtEHDPSk3M7Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/reset/illustration.vue?macro=true";
import { default as basicdnNXMihoevMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signin/basic.vue?macro=true";
import { default as coverMxdokRHEiEMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signin/cover.vue?macro=true";
import { default as illustrationm9rOfU31tRMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signin/illustration.vue?macro=true";
import { default as basic4JEwSpMKE1Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signup/basic.vue?macro=true";
import { default as coverwX7C5NrZGxMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signup/cover.vue?macro=true";
import { default as illustrationDsCLbkf6pLMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signup/illustration.vue?macro=true";
import { default as basic9xquteaoTkMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/verification/basic.vue?macro=true";
import { default as coverTLZjWunNZAMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/verification/cover.vue?macro=true";
import { default as illustrationQM6yrfiMAHMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/authentication/verification/illustration.vue?macro=true";
import { default as index7ef8fqduwhMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/cancel-reasons/index.vue?macro=true";
import { default as indexqqvD6RW31MMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coach-categories/add-category/index.vue?macro=true";
import { default as indexsb52DlW04QMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coach-categories/index.vue?macro=true";
import { default as indexHExRbKfdLZMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coach-levels/index.vue?macro=true";
import { default as indexfy5dXvhdpWMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coaches/[id]/edit/index.vue?macro=true";
import { default as index26oXrXH3tuMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coaches/[id]/show/index.vue?macro=true";
import { default as indexz3PMZDfEDcMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coaches/index.vue?macro=true";
import { default as indexMxJAQgDMYzMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/coaches/update-coaches/[type]/index.vue?macro=true";
import { default as indexOn7N1WuSuDMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-list/index.vue?macro=true";
import { default as index2Vi5YNOig5Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-reasons/[id]/edit/index.vue?macro=true";
import { default as indexG56hY7s5x0Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-reasons/index.vue?macro=true";
import { default as indexIbOvmvmEBZMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-reasons/new-reason/index.vue?macro=true";
import { default as index5W6PwsQqa7Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/customers/[id]/edit/index.vue?macro=true";
import { default as indexGvBCowxqa6Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/customers/[id]/show/index.vue?macro=true";
import { default as indexAqapHyv8bbMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/customers/index.vue?macro=true";
import { default as indexDxAti3UAmLMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboard/index.vue?macro=true";
import { default as automotive9dkN2Ac3mfMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/automotive.vue?macro=true";
import { default as crmwKo7nlc1WWMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/crm.vue?macro=true";
import { default as defaultKUr4YJFvGmMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/default.vue?macro=true";
import { default as landingPEtLQ1YWJsMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/landing.vue?macro=true";
import { default as smart_45homextApYvsUQMMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/smart-home.vue?macro=true";
import { default as vr_45default829My3OK6aMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/vr/vr-default.vue?macro=true";
import { default as vr_45infojR0Po7avPrMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/vr/vr-info.vue?macro=true";
import { default as index9zqfOtSz2dMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/discounts/add/index.vue?macro=true";
import { default as indexY7gTgU04HXMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/discounts/index.vue?macro=true";
import { default as indexxVQDfcxiu3Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/discounts/update/[id]/index.vue?macro=true";
import { default as order_45detailsznBBJpr4fjMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/orders/order-details.vue?macro=true";
import { default as order_45listuWRWemanyzMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/orders/order-list.vue?macro=true";
import { default as overviewjIpsMx1inQMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/overview.vue?macro=true";
import { default as edit_45productrx21MKixgWMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/edit-product.vue?macro=true";
import { default as new_45productUYXOaTMy8BMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/new-product.vue?macro=true";
import { default as product_45listWC6cjyxPevMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/product-list.vue?macro=true";
import { default as product_45pageXa9goF0jdiMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/product-page.vue?macro=true";
import { default as referral6SteSUHnpVMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/referral.vue?macro=true";
import { default as indexo6RNbbZc0cMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/faq-categories/index.vue?macro=true";
import { default as indexlYXNSCC6ikMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/faq/index.vue?macro=true";
import { default as indexYUl16hwCsIMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/index.vue?macro=true";
import { default as indexfYLow9jzSPMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/orders/[id]/order-details/index.vue?macro=true";
import { default as indexPogr54PWjKMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/orders/index.vue?macro=true";
import { default as billingg7jubp3Mm5Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/billing.vue?macro=true";
import { default as invoiceUcNZatgAGqMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/invoice.vue?macro=true";
import { default as security4KKuqKSUDaMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/security.vue?macro=true";
import { default as settingstfToBDT78dMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/settings.vue?macro=true";
import { default as chartsgvCzbMFvvIMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/charts.vue?macro=true";
import { default as notificationsl3TS8wQbSgMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/notifications.vue?macro=true";
import { default as pricing_45pageMt6kqHJS4WMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/pricing-page.vue?macro=true";
import { default as all_45projectsavpoTUkZrQMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/profile/all-projects.vue?macro=true";
import { default as profile_45overview8Eg1DWJEegMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/profile/profile-overview.vue?macro=true";
import { default as teamsS2oJbfBVIxMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/profile/teams.vue?macro=true";
import { default as generalhjzZqrooyBMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/projects/general.vue?macro=true";
import { default as new_45projectWwq9HCFEJzMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/projects/new-project.vue?macro=true";
import { default as timelineFcVPYmNyjEMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/projects/timeline.vue?macro=true";
import { default as rtl_45pageWMfm72LGV9Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/rtl-page.vue?macro=true";
import { default as sweet_45alerts5ca2JDmVESMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/sweet-alerts.vue?macro=true";
import { default as indexb3OqsIiM1oMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/terms-conditions-customer/index.vue?macro=true";
import { default as indexlInpzrF3ppMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/terms-conditions-mentor/index.vue?macro=true";
import { default as new_45userlj8A6ibSYfMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/users/new-user.vue?macro=true";
import { default as reportsrF56HoxgkbMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/users/reports.vue?macro=true";
import { default as widgetsxhpjHu4ZNeMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/pages/widgets.vue?macro=true";
import { default as index5DK1w8Ek0CMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/payments/[id]/record-session-balance/index.vue?macro=true";
import { default as indexGCmf3Io0KOMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/payments/index.vue?macro=true";
import { default as indexno0yTBxb9pMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/profile/edit-profile/index.vue?macro=true";
import { default as indexaxDlu2lUiuMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/profile/index.vue?macro=true";
import { default as indexPAkgWvXEWaMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/rates-statistics/index.vue?macro=true";
import { default as indexWYyTdUeoehMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/rates/coaches-by-customers/[id]/details/index.vue?macro=true";
import { default as indexrhL4lJuZCyMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/rates/coaches-by-customers/index.vue?macro=true";
import { default as index0NuwyQLgfTMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/rates/customers-by-coaches/index.vue?macro=true";
import { default as indexSyxMss3868Meta } from "/home/runner/work/calwe-admin/calwe-admin/pages/request-update-coach-data/[id]/update-coach-data/index.vue?macro=true";
import { default as indexp4p7RwKNlIMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/request-update-coach-data/index.vue?macro=true";
import { default as indexyQIsSw4pMXMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/sessions/index.vue?macro=true";
import { default as _91id_93KGDdhtGjhgMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/sessions/session-details/[id].vue?macro=true";
import { default as indexwneDLoD4FOMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/general-settings/index.vue?macro=true";
import { default as indexEP2oePFjhFMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/professional-data-settings/index.vue?macro=true";
import { default as edit_45roleOU9Em3sWGBMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/roles-permissions/[id]/edit-role.vue?macro=true";
import { default as indexzJHyoj3RGvMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/roles-permissions/add-role/index.vue?macro=true";
import { default as indexXS2vrQHBykMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/roles-permissions/index.vue?macro=true";
import { default as indexmqbQxUPRbhMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/session-settings/[id]/edit/index.vue?macro=true";
import { default as indexJOCAaXLUvHMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/session-settings/index.vue?macro=true";
import { default as indexdTzb5gvCKbMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/settings/session-settings/new-session/index.vue?macro=true";
import { default as indexZJ23pzsoQtMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/test/index.vue?macro=true";
import { default as indexHi2GZMKypLMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/transactions-log/index.vue?macro=true";
import { default as indexmQIWTX1YtVMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/users/[id]/edit/index.vue?macro=true";
import { default as indexM0IykqdYtxMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/users/[id]/show/index.vue?macro=true";
import { default as indexdaD3OV0lmXMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/users/index.vue?macro=true";
import { default as indexxJUAvMD6nbMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/wallets/[id]/show/index.vue?macro=true";
import { default as index5jJNzDt4qlMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/wallets/index.vue?macro=true";
import { default as coachesXgHEdgJRsnMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/warning/coaches.vue?macro=true";
import { default as customersiqSpExSBeaMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/warning/customers.vue?macro=true";
import { default as indexSQcBfLAbYYMeta } from "/home/runner/work/calwe-admin/calwe-admin/pages/warning/reviewed/index.vue?macro=true";
export default [
  {
    name: analyticsiXsrLVkyprMeta?.name ?? "applications-analytics",
    path: analyticsiXsrLVkyprMeta?.path ?? "/applications/analytics",
    meta: analyticsiXsrLVkyprMeta || {},
    alias: analyticsiXsrLVkyprMeta?.alias || [],
    redirect: analyticsiXsrLVkyprMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/applications/analytics.vue").then(m => m.default || m)
  },
  {
    name: calendarYLQONM3bY2Meta?.name ?? "applications-calendar",
    path: calendarYLQONM3bY2Meta?.path ?? "/applications/calendar",
    meta: calendarYLQONM3bY2Meta || {},
    alias: calendarYLQONM3bY2Meta?.alias || [],
    redirect: calendarYLQONM3bY2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/applications/calendar.vue").then(m => m.default || m)
  },
  {
    name: data_45tablesZmWakAjO2fMeta?.name ?? "applications-data-tables",
    path: data_45tablesZmWakAjO2fMeta?.path ?? "/applications/data-tables",
    meta: data_45tablesZmWakAjO2fMeta || {},
    alias: data_45tablesZmWakAjO2fMeta?.alias || [],
    redirect: data_45tablesZmWakAjO2fMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/applications/data-tables.vue").then(m => m.default || m)
  },
  {
    name: wizardzFsUKh6qtAMeta?.name ?? "applications-wizard",
    path: wizardzFsUKh6qtAMeta?.path ?? "/applications/wizard",
    meta: wizardzFsUKh6qtAMeta || {},
    alias: wizardzFsUKh6qtAMeta?.alias || [],
    redirect: wizardzFsUKh6qtAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/applications/wizard.vue").then(m => m.default || m)
  },
  {
    name: indexqJC0gqqwl4Meta?.name ?? "auth-activate-account-token-email",
    path: indexqJC0gqqwl4Meta?.path ?? "/auth/activate-account/:token()/:email()",
    meta: indexqJC0gqqwl4Meta || {},
    alias: indexqJC0gqqwl4Meta?.alias || [],
    redirect: indexqJC0gqqwl4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/auth/activate-account/[token]/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: forget_45passwordarq6BsqJReMeta?.name ?? "auth-forget-password",
    path: forget_45passwordarq6BsqJReMeta?.path ?? "/auth/forget-password",
    meta: forget_45passwordarq6BsqJReMeta || {},
    alias: forget_45passwordarq6BsqJReMeta?.alias || [],
    redirect: forget_45passwordarq6BsqJReMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/auth/forget-password.vue").then(m => m.default || m)
  },
  {
    name: indexF7eTnYZ0eIMeta?.name ?? "auth-reset-password-token",
    path: indexF7eTnYZ0eIMeta?.path ?? "/auth/reset-password/:token()",
    meta: indexF7eTnYZ0eIMeta || {},
    alias: indexF7eTnYZ0eIMeta?.alias || [],
    redirect: indexF7eTnYZ0eIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/auth/reset-password/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: signinzZgzYpEAMnMeta?.name ?? "auth-signin",
    path: signinzZgzYpEAMnMeta?.path ?? "/auth/signin",
    meta: signinzZgzYpEAMnMeta || {},
    alias: signinzZgzYpEAMnMeta?.alias || [],
    redirect: signinzZgzYpEAMnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: error404CXAHsCMoIVMeta?.name ?? "authentication-error-error404",
    path: error404CXAHsCMoIVMeta?.path ?? "/authentication/error/error404",
    meta: error404CXAHsCMoIVMeta || {},
    alias: error404CXAHsCMoIVMeta?.alias || [],
    redirect: error404CXAHsCMoIVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/error/error404.vue").then(m => m.default || m)
  },
  {
    name: error500p3FM3vors7Meta?.name ?? "authentication-error-error500",
    path: error500p3FM3vors7Meta?.path ?? "/authentication/error/error500",
    meta: error500p3FM3vors7Meta || {},
    alias: error500p3FM3vors7Meta?.alias || [],
    redirect: error500p3FM3vors7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/error/error500.vue").then(m => m.default || m)
  },
  {
    name: basicgs5DGJoW7kMeta?.name ?? "authentication-lock-basic",
    path: basicgs5DGJoW7kMeta?.path ?? "/authentication/lock/basic",
    meta: basicgs5DGJoW7kMeta || {},
    alias: basicgs5DGJoW7kMeta?.alias || [],
    redirect: basicgs5DGJoW7kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/lock/basic.vue").then(m => m.default || m)
  },
  {
    name: cover0ItFFqMtUFMeta?.name ?? "authentication-lock-cover",
    path: cover0ItFFqMtUFMeta?.path ?? "/authentication/lock/cover",
    meta: cover0ItFFqMtUFMeta || {},
    alias: cover0ItFFqMtUFMeta?.alias || [],
    redirect: cover0ItFFqMtUFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/lock/cover.vue").then(m => m.default || m)
  },
  {
    name: illustration8gRBh9PtgFMeta?.name ?? "authentication-lock-illustration",
    path: illustration8gRBh9PtgFMeta?.path ?? "/authentication/lock/illustration",
    meta: illustration8gRBh9PtgFMeta || {},
    alias: illustration8gRBh9PtgFMeta?.alias || [],
    redirect: illustration8gRBh9PtgFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/lock/illustration.vue").then(m => m.default || m)
  },
  {
    name: basicClWKqkOuoxMeta?.name ?? "authentication-reset-basic",
    path: basicClWKqkOuoxMeta?.path ?? "/authentication/reset/basic",
    meta: basicClWKqkOuoxMeta || {},
    alias: basicClWKqkOuoxMeta?.alias || [],
    redirect: basicClWKqkOuoxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/reset/basic.vue").then(m => m.default || m)
  },
  {
    name: coverdLrZlV3LlOMeta?.name ?? "authentication-reset-cover",
    path: coverdLrZlV3LlOMeta?.path ?? "/authentication/reset/cover",
    meta: coverdLrZlV3LlOMeta || {},
    alias: coverdLrZlV3LlOMeta?.alias || [],
    redirect: coverdLrZlV3LlOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/reset/cover.vue").then(m => m.default || m)
  },
  {
    name: illustrationtEHDPSk3M7Meta?.name ?? "authentication-reset-illustration",
    path: illustrationtEHDPSk3M7Meta?.path ?? "/authentication/reset/illustration",
    meta: illustrationtEHDPSk3M7Meta || {},
    alias: illustrationtEHDPSk3M7Meta?.alias || [],
    redirect: illustrationtEHDPSk3M7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/reset/illustration.vue").then(m => m.default || m)
  },
  {
    name: basicdnNXMihoevMeta?.name ?? "authentication-signin-basic",
    path: basicdnNXMihoevMeta?.path ?? "/authentication/signin/basic",
    meta: basicdnNXMihoevMeta || {},
    alias: basicdnNXMihoevMeta?.alias || [],
    redirect: basicdnNXMihoevMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signin/basic.vue").then(m => m.default || m)
  },
  {
    name: coverMxdokRHEiEMeta?.name ?? "authentication-signin-cover",
    path: coverMxdokRHEiEMeta?.path ?? "/authentication/signin/cover",
    meta: coverMxdokRHEiEMeta || {},
    alias: coverMxdokRHEiEMeta?.alias || [],
    redirect: coverMxdokRHEiEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signin/cover.vue").then(m => m.default || m)
  },
  {
    name: illustrationm9rOfU31tRMeta?.name ?? "authentication-signin-illustration",
    path: illustrationm9rOfU31tRMeta?.path ?? "/authentication/signin/illustration",
    meta: illustrationm9rOfU31tRMeta || {},
    alias: illustrationm9rOfU31tRMeta?.alias || [],
    redirect: illustrationm9rOfU31tRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signin/illustration.vue").then(m => m.default || m)
  },
  {
    name: basic4JEwSpMKE1Meta?.name ?? "authentication-signup-basic",
    path: basic4JEwSpMKE1Meta?.path ?? "/authentication/signup/basic",
    meta: basic4JEwSpMKE1Meta || {},
    alias: basic4JEwSpMKE1Meta?.alias || [],
    redirect: basic4JEwSpMKE1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signup/basic.vue").then(m => m.default || m)
  },
  {
    name: coverwX7C5NrZGxMeta?.name ?? "authentication-signup-cover",
    path: coverwX7C5NrZGxMeta?.path ?? "/authentication/signup/cover",
    meta: coverwX7C5NrZGxMeta || {},
    alias: coverwX7C5NrZGxMeta?.alias || [],
    redirect: coverwX7C5NrZGxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signup/cover.vue").then(m => m.default || m)
  },
  {
    name: illustrationDsCLbkf6pLMeta?.name ?? "authentication-signup-illustration",
    path: illustrationDsCLbkf6pLMeta?.path ?? "/authentication/signup/illustration",
    meta: illustrationDsCLbkf6pLMeta || {},
    alias: illustrationDsCLbkf6pLMeta?.alias || [],
    redirect: illustrationDsCLbkf6pLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/signup/illustration.vue").then(m => m.default || m)
  },
  {
    name: basic9xquteaoTkMeta?.name ?? "authentication-verification-basic",
    path: basic9xquteaoTkMeta?.path ?? "/authentication/verification/basic",
    meta: basic9xquteaoTkMeta || {},
    alias: basic9xquteaoTkMeta?.alias || [],
    redirect: basic9xquteaoTkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/verification/basic.vue").then(m => m.default || m)
  },
  {
    name: coverTLZjWunNZAMeta?.name ?? "authentication-verification-cover",
    path: coverTLZjWunNZAMeta?.path ?? "/authentication/verification/cover",
    meta: coverTLZjWunNZAMeta || {},
    alias: coverTLZjWunNZAMeta?.alias || [],
    redirect: coverTLZjWunNZAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/verification/cover.vue").then(m => m.default || m)
  },
  {
    name: illustrationQM6yrfiMAHMeta?.name ?? "authentication-verification-illustration",
    path: illustrationQM6yrfiMAHMeta?.path ?? "/authentication/verification/illustration",
    meta: illustrationQM6yrfiMAHMeta || {},
    alias: illustrationQM6yrfiMAHMeta?.alias || [],
    redirect: illustrationQM6yrfiMAHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/authentication/verification/illustration.vue").then(m => m.default || m)
  },
  {
    name: index7ef8fqduwhMeta?.name ?? "cancel-reasons",
    path: index7ef8fqduwhMeta?.path ?? "/cancel-reasons",
    meta: index7ef8fqduwhMeta || {},
    alias: index7ef8fqduwhMeta?.alias || [],
    redirect: index7ef8fqduwhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/cancel-reasons/index.vue").then(m => m.default || m)
  },
  {
    name: indexqqvD6RW31MMeta?.name ?? "coach-categories-add-category",
    path: indexqqvD6RW31MMeta?.path ?? "/coach-categories/add-category",
    meta: indexqqvD6RW31MMeta || {},
    alias: indexqqvD6RW31MMeta?.alias || [],
    redirect: indexqqvD6RW31MMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coach-categories/add-category/index.vue").then(m => m.default || m)
  },
  {
    name: indexsb52DlW04QMeta?.name ?? "coach-categories",
    path: indexsb52DlW04QMeta?.path ?? "/coach-categories",
    meta: indexsb52DlW04QMeta || {},
    alias: indexsb52DlW04QMeta?.alias || [],
    redirect: indexsb52DlW04QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coach-categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexHExRbKfdLZMeta?.name ?? "coach-levels",
    path: indexHExRbKfdLZMeta?.path ?? "/coach-levels",
    meta: indexHExRbKfdLZMeta || {},
    alias: indexHExRbKfdLZMeta?.alias || [],
    redirect: indexHExRbKfdLZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coach-levels/index.vue").then(m => m.default || m)
  },
  {
    name: indexfy5dXvhdpWMeta?.name ?? "coaches-id-edit",
    path: indexfy5dXvhdpWMeta?.path ?? "/coaches/:id()/edit",
    meta: indexfy5dXvhdpWMeta || {},
    alias: indexfy5dXvhdpWMeta?.alias || [],
    redirect: indexfy5dXvhdpWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coaches/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index26oXrXH3tuMeta?.name ?? "coaches-id-show",
    path: index26oXrXH3tuMeta?.path ?? "/coaches/:id()/show",
    meta: index26oXrXH3tuMeta || {},
    alias: index26oXrXH3tuMeta?.alias || [],
    redirect: index26oXrXH3tuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coaches/[id]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexz3PMZDfEDcMeta?.name ?? "coaches",
    path: indexz3PMZDfEDcMeta?.path ?? "/coaches",
    meta: indexz3PMZDfEDcMeta || {},
    alias: indexz3PMZDfEDcMeta?.alias || [],
    redirect: indexz3PMZDfEDcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coaches/index.vue").then(m => m.default || m)
  },
  {
    name: indexMxJAQgDMYzMeta?.name ?? "coaches-update-coaches-type",
    path: indexMxJAQgDMYzMeta?.path ?? "/coaches/update-coaches/:type()",
    meta: indexMxJAQgDMYzMeta || {},
    alias: indexMxJAQgDMYzMeta?.alias || [],
    redirect: indexMxJAQgDMYzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/coaches/update-coaches/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOn7N1WuSuDMeta?.name ?? "contact-us-contact-us-list",
    path: indexOn7N1WuSuDMeta?.path ?? "/contact-us/contact-us-list",
    meta: indexOn7N1WuSuDMeta || {},
    alias: indexOn7N1WuSuDMeta?.alias || [],
    redirect: indexOn7N1WuSuDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-list/index.vue").then(m => m.default || m)
  },
  {
    name: index2Vi5YNOig5Meta?.name ?? "contact-us-contact-us-reasons-id-edit",
    path: index2Vi5YNOig5Meta?.path ?? "/contact-us/contact-us-reasons/:id()/edit",
    meta: index2Vi5YNOig5Meta || {},
    alias: index2Vi5YNOig5Meta?.alias || [],
    redirect: index2Vi5YNOig5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-reasons/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexG56hY7s5x0Meta?.name ?? "contact-us-contact-us-reasons",
    path: indexG56hY7s5x0Meta?.path ?? "/contact-us/contact-us-reasons",
    meta: indexG56hY7s5x0Meta || {},
    alias: indexG56hY7s5x0Meta?.alias || [],
    redirect: indexG56hY7s5x0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-reasons/index.vue").then(m => m.default || m)
  },
  {
    name: indexIbOvmvmEBZMeta?.name ?? "contact-us-contact-us-reasons-new-reason",
    path: indexIbOvmvmEBZMeta?.path ?? "/contact-us/contact-us-reasons/new-reason",
    meta: indexIbOvmvmEBZMeta || {},
    alias: indexIbOvmvmEBZMeta?.alias || [],
    redirect: indexIbOvmvmEBZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/contact-us/contact-us-reasons/new-reason/index.vue").then(m => m.default || m)
  },
  {
    name: index5W6PwsQqa7Meta?.name ?? "customers-id-edit",
    path: index5W6PwsQqa7Meta?.path ?? "/customers/:id()/edit",
    meta: index5W6PwsQqa7Meta || {},
    alias: index5W6PwsQqa7Meta?.alias || [],
    redirect: index5W6PwsQqa7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/customers/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvBCowxqa6Meta?.name ?? "customers-id-show",
    path: indexGvBCowxqa6Meta?.path ?? "/customers/:id()/show",
    meta: indexGvBCowxqa6Meta || {},
    alias: indexGvBCowxqa6Meta?.alias || [],
    redirect: indexGvBCowxqa6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/customers/[id]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexAqapHyv8bbMeta?.name ?? "customers",
    path: indexAqapHyv8bbMeta?.path ?? "/customers",
    meta: indexAqapHyv8bbMeta || {},
    alias: indexAqapHyv8bbMeta?.alias || [],
    redirect: indexAqapHyv8bbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexDxAti3UAmLMeta?.name ?? "dashboard",
    path: indexDxAti3UAmLMeta?.path ?? "/dashboard",
    meta: indexDxAti3UAmLMeta || {},
    alias: indexDxAti3UAmLMeta?.alias || [],
    redirect: indexDxAti3UAmLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: automotive9dkN2Ac3mfMeta?.name ?? "dashboards-automotive",
    path: automotive9dkN2Ac3mfMeta?.path ?? "/dashboards/automotive",
    meta: automotive9dkN2Ac3mfMeta || {},
    alias: automotive9dkN2Ac3mfMeta?.alias || [],
    redirect: automotive9dkN2Ac3mfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/automotive.vue").then(m => m.default || m)
  },
  {
    name: crmwKo7nlc1WWMeta?.name ?? "dashboards-crm",
    path: crmwKo7nlc1WWMeta?.path ?? "/dashboards/crm",
    meta: crmwKo7nlc1WWMeta || {},
    alias: crmwKo7nlc1WWMeta?.alias || [],
    redirect: crmwKo7nlc1WWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/crm.vue").then(m => m.default || m)
  },
  {
    name: defaultKUr4YJFvGmMeta?.name ?? "dashboards-default",
    path: defaultKUr4YJFvGmMeta?.path ?? "/dashboards/default",
    meta: defaultKUr4YJFvGmMeta || {},
    alias: defaultKUr4YJFvGmMeta?.alias || [],
    redirect: defaultKUr4YJFvGmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/default.vue").then(m => m.default || m)
  },
  {
    name: landingPEtLQ1YWJsMeta?.name ?? "dashboards-landing",
    path: landingPEtLQ1YWJsMeta?.path ?? "/dashboards/landing",
    meta: landingPEtLQ1YWJsMeta || {},
    alias: landingPEtLQ1YWJsMeta?.alias || [],
    redirect: landingPEtLQ1YWJsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/landing.vue").then(m => m.default || m)
  },
  {
    name: smart_45homextApYvsUQMMeta?.name ?? "dashboards-smart-home",
    path: smart_45homextApYvsUQMMeta?.path ?? "/dashboards/smart-home",
    meta: smart_45homextApYvsUQMMeta || {},
    alias: smart_45homextApYvsUQMMeta?.alias || [],
    redirect: smart_45homextApYvsUQMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/smart-home.vue").then(m => m.default || m)
  },
  {
    name: vr_45default829My3OK6aMeta?.name ?? "dashboards-vr-vr-default",
    path: vr_45default829My3OK6aMeta?.path ?? "/dashboards/vr/vr-default",
    meta: vr_45default829My3OK6aMeta || {},
    alias: vr_45default829My3OK6aMeta?.alias || [],
    redirect: vr_45default829My3OK6aMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/vr/vr-default.vue").then(m => m.default || m)
  },
  {
    name: vr_45infojR0Po7avPrMeta?.name ?? "dashboards-vr-vr-info",
    path: vr_45infojR0Po7avPrMeta?.path ?? "/dashboards/vr/vr-info",
    meta: vr_45infojR0Po7avPrMeta || {},
    alias: vr_45infojR0Po7avPrMeta?.alias || [],
    redirect: vr_45infojR0Po7avPrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/dashboards/vr/vr-info.vue").then(m => m.default || m)
  },
  {
    name: index9zqfOtSz2dMeta?.name ?? "discounts-add",
    path: index9zqfOtSz2dMeta?.path ?? "/discounts/add",
    meta: index9zqfOtSz2dMeta || {},
    alias: index9zqfOtSz2dMeta?.alias || [],
    redirect: index9zqfOtSz2dMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/discounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexY7gTgU04HXMeta?.name ?? "discounts",
    path: indexY7gTgU04HXMeta?.path ?? "/discounts",
    meta: indexY7gTgU04HXMeta || {},
    alias: indexY7gTgU04HXMeta?.alias || [],
    redirect: indexY7gTgU04HXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexxVQDfcxiu3Meta?.name ?? "discounts-update-id",
    path: indexxVQDfcxiu3Meta?.path ?? "/discounts/update/:id()",
    meta: indexxVQDfcxiu3Meta || {},
    alias: indexxVQDfcxiu3Meta?.alias || [],
    redirect: indexxVQDfcxiu3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/discounts/update/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: order_45detailsznBBJpr4fjMeta?.name ?? "ecommerce-orders-order-details",
    path: order_45detailsznBBJpr4fjMeta?.path ?? "/ecommerce/orders/order-details",
    meta: order_45detailsznBBJpr4fjMeta || {},
    alias: order_45detailsznBBJpr4fjMeta?.alias || [],
    redirect: order_45detailsznBBJpr4fjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/orders/order-details.vue").then(m => m.default || m)
  },
  {
    name: order_45listuWRWemanyzMeta?.name ?? "ecommerce-orders-order-list",
    path: order_45listuWRWemanyzMeta?.path ?? "/ecommerce/orders/order-list",
    meta: order_45listuWRWemanyzMeta || {},
    alias: order_45listuWRWemanyzMeta?.alias || [],
    redirect: order_45listuWRWemanyzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/orders/order-list.vue").then(m => m.default || m)
  },
  {
    name: overviewjIpsMx1inQMeta?.name ?? "ecommerce-overview",
    path: overviewjIpsMx1inQMeta?.path ?? "/ecommerce/overview",
    meta: overviewjIpsMx1inQMeta || {},
    alias: overviewjIpsMx1inQMeta?.alias || [],
    redirect: overviewjIpsMx1inQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/overview.vue").then(m => m.default || m)
  },
  {
    name: edit_45productrx21MKixgWMeta?.name ?? "ecommerce-products-edit-product",
    path: edit_45productrx21MKixgWMeta?.path ?? "/ecommerce/products/edit-product",
    meta: edit_45productrx21MKixgWMeta || {},
    alias: edit_45productrx21MKixgWMeta?.alias || [],
    redirect: edit_45productrx21MKixgWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/edit-product.vue").then(m => m.default || m)
  },
  {
    name: new_45productUYXOaTMy8BMeta?.name ?? "ecommerce-products-new-product",
    path: new_45productUYXOaTMy8BMeta?.path ?? "/ecommerce/products/new-product",
    meta: new_45productUYXOaTMy8BMeta || {},
    alias: new_45productUYXOaTMy8BMeta?.alias || [],
    redirect: new_45productUYXOaTMy8BMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/new-product.vue").then(m => m.default || m)
  },
  {
    name: product_45listWC6cjyxPevMeta?.name ?? "ecommerce-products-product-list",
    path: product_45listWC6cjyxPevMeta?.path ?? "/ecommerce/products/product-list",
    meta: product_45listWC6cjyxPevMeta || {},
    alias: product_45listWC6cjyxPevMeta?.alias || [],
    redirect: product_45listWC6cjyxPevMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/product-list.vue").then(m => m.default || m)
  },
  {
    name: product_45pageXa9goF0jdiMeta?.name ?? "ecommerce-products-product-page",
    path: product_45pageXa9goF0jdiMeta?.path ?? "/ecommerce/products/product-page",
    meta: product_45pageXa9goF0jdiMeta || {},
    alias: product_45pageXa9goF0jdiMeta?.alias || [],
    redirect: product_45pageXa9goF0jdiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/products/product-page.vue").then(m => m.default || m)
  },
  {
    name: referral6SteSUHnpVMeta?.name ?? "ecommerce-referral",
    path: referral6SteSUHnpVMeta?.path ?? "/ecommerce/referral",
    meta: referral6SteSUHnpVMeta || {},
    alias: referral6SteSUHnpVMeta?.alias || [],
    redirect: referral6SteSUHnpVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/ecommerce/referral.vue").then(m => m.default || m)
  },
  {
    name: indexo6RNbbZc0cMeta?.name ?? "faq-categories",
    path: indexo6RNbbZc0cMeta?.path ?? "/faq-categories",
    meta: indexo6RNbbZc0cMeta || {},
    alias: indexo6RNbbZc0cMeta?.alias || [],
    redirect: indexo6RNbbZc0cMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/faq-categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexlYXNSCC6ikMeta?.name ?? "faq",
    path: indexlYXNSCC6ikMeta?.path ?? "/faq",
    meta: indexlYXNSCC6ikMeta || {},
    alias: indexlYXNSCC6ikMeta?.alias || [],
    redirect: indexlYXNSCC6ikMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexYUl16hwCsIMeta?.name ?? "index",
    path: indexYUl16hwCsIMeta?.path ?? "/",
    meta: indexYUl16hwCsIMeta || {},
    alias: indexYUl16hwCsIMeta?.alias || [],
    redirect: indexYUl16hwCsIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfYLow9jzSPMeta?.name ?? "orders-id-order-details",
    path: indexfYLow9jzSPMeta?.path ?? "/orders/:id()/order-details",
    meta: indexfYLow9jzSPMeta || {},
    alias: indexfYLow9jzSPMeta?.alias || [],
    redirect: indexfYLow9jzSPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/orders/[id]/order-details/index.vue").then(m => m.default || m)
  },
  {
    name: indexPogr54PWjKMeta?.name ?? "orders",
    path: indexPogr54PWjKMeta?.path ?? "/orders",
    meta: indexPogr54PWjKMeta || {},
    alias: indexPogr54PWjKMeta?.alias || [],
    redirect: indexPogr54PWjKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: billingg7jubp3Mm5Meta?.name ?? "pages-account-billing",
    path: billingg7jubp3Mm5Meta?.path ?? "/pages/account/billing",
    meta: billingg7jubp3Mm5Meta || {},
    alias: billingg7jubp3Mm5Meta?.alias || [],
    redirect: billingg7jubp3Mm5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: invoiceUcNZatgAGqMeta?.name ?? "pages-account-invoice",
    path: invoiceUcNZatgAGqMeta?.path ?? "/pages/account/invoice",
    meta: invoiceUcNZatgAGqMeta || {},
    alias: invoiceUcNZatgAGqMeta?.alias || [],
    redirect: invoiceUcNZatgAGqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/invoice.vue").then(m => m.default || m)
  },
  {
    name: security4KKuqKSUDaMeta?.name ?? "pages-account-security",
    path: security4KKuqKSUDaMeta?.path ?? "/pages/account/security",
    meta: security4KKuqKSUDaMeta || {},
    alias: security4KKuqKSUDaMeta?.alias || [],
    redirect: security4KKuqKSUDaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: settingstfToBDT78dMeta?.name ?? "pages-account-settings",
    path: settingstfToBDT78dMeta?.path ?? "/pages/account/settings",
    meta: settingstfToBDT78dMeta || {},
    alias: settingstfToBDT78dMeta?.alias || [],
    redirect: settingstfToBDT78dMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: chartsgvCzbMFvvIMeta?.name ?? "pages-charts",
    path: chartsgvCzbMFvvIMeta?.path ?? "/pages/charts",
    meta: chartsgvCzbMFvvIMeta || {},
    alias: chartsgvCzbMFvvIMeta?.alias || [],
    redirect: chartsgvCzbMFvvIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/charts.vue").then(m => m.default || m)
  },
  {
    name: notificationsl3TS8wQbSgMeta?.name ?? "pages-notifications",
    path: notificationsl3TS8wQbSgMeta?.path ?? "/pages/notifications",
    meta: notificationsl3TS8wQbSgMeta || {},
    alias: notificationsl3TS8wQbSgMeta?.alias || [],
    redirect: notificationsl3TS8wQbSgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: pricing_45pageMt6kqHJS4WMeta?.name ?? "pages-pricing-page",
    path: pricing_45pageMt6kqHJS4WMeta?.path ?? "/pages/pricing-page",
    meta: pricing_45pageMt6kqHJS4WMeta || {},
    alias: pricing_45pageMt6kqHJS4WMeta?.alias || [],
    redirect: pricing_45pageMt6kqHJS4WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/pricing-page.vue").then(m => m.default || m)
  },
  {
    name: all_45projectsavpoTUkZrQMeta?.name ?? "pages-profile-all-projects",
    path: all_45projectsavpoTUkZrQMeta?.path ?? "/pages/profile/all-projects",
    meta: all_45projectsavpoTUkZrQMeta || {},
    alias: all_45projectsavpoTUkZrQMeta?.alias || [],
    redirect: all_45projectsavpoTUkZrQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/profile/all-projects.vue").then(m => m.default || m)
  },
  {
    name: profile_45overview8Eg1DWJEegMeta?.name ?? "pages-profile-profile-overview",
    path: profile_45overview8Eg1DWJEegMeta?.path ?? "/pages/profile/profile-overview",
    meta: profile_45overview8Eg1DWJEegMeta || {},
    alias: profile_45overview8Eg1DWJEegMeta?.alias || [],
    redirect: profile_45overview8Eg1DWJEegMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/profile/profile-overview.vue").then(m => m.default || m)
  },
  {
    name: teamsS2oJbfBVIxMeta?.name ?? "pages-profile-teams",
    path: teamsS2oJbfBVIxMeta?.path ?? "/pages/profile/teams",
    meta: teamsS2oJbfBVIxMeta || {},
    alias: teamsS2oJbfBVIxMeta?.alias || [],
    redirect: teamsS2oJbfBVIxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/profile/teams.vue").then(m => m.default || m)
  },
  {
    name: generalhjzZqrooyBMeta?.name ?? "pages-projects-general",
    path: generalhjzZqrooyBMeta?.path ?? "/pages/projects/general",
    meta: generalhjzZqrooyBMeta || {},
    alias: generalhjzZqrooyBMeta?.alias || [],
    redirect: generalhjzZqrooyBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/projects/general.vue").then(m => m.default || m)
  },
  {
    name: new_45projectWwq9HCFEJzMeta?.name ?? "pages-projects-new-project",
    path: new_45projectWwq9HCFEJzMeta?.path ?? "/pages/projects/new-project",
    meta: new_45projectWwq9HCFEJzMeta || {},
    alias: new_45projectWwq9HCFEJzMeta?.alias || [],
    redirect: new_45projectWwq9HCFEJzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/projects/new-project.vue").then(m => m.default || m)
  },
  {
    name: timelineFcVPYmNyjEMeta?.name ?? "pages-projects-timeline",
    path: timelineFcVPYmNyjEMeta?.path ?? "/pages/projects/timeline",
    meta: timelineFcVPYmNyjEMeta || {},
    alias: timelineFcVPYmNyjEMeta?.alias || [],
    redirect: timelineFcVPYmNyjEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/projects/timeline.vue").then(m => m.default || m)
  },
  {
    name: rtl_45pageWMfm72LGV9Meta?.name ?? "pages-rtl-page",
    path: rtl_45pageWMfm72LGV9Meta?.path ?? "/pages/rtl-page",
    meta: rtl_45pageWMfm72LGV9Meta || {},
    alias: rtl_45pageWMfm72LGV9Meta?.alias || [],
    redirect: rtl_45pageWMfm72LGV9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/rtl-page.vue").then(m => m.default || m)
  },
  {
    name: sweet_45alerts5ca2JDmVESMeta?.name ?? "pages-sweet-alerts",
    path: sweet_45alerts5ca2JDmVESMeta?.path ?? "/pages/sweet-alerts",
    meta: sweet_45alerts5ca2JDmVESMeta || {},
    alias: sweet_45alerts5ca2JDmVESMeta?.alias || [],
    redirect: sweet_45alerts5ca2JDmVESMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/sweet-alerts.vue").then(m => m.default || m)
  },
  {
    name: indexb3OqsIiM1oMeta?.name ?? "pages-terms-conditions-customer",
    path: indexb3OqsIiM1oMeta?.path ?? "/pages/terms-conditions-customer",
    meta: indexb3OqsIiM1oMeta || {},
    alias: indexb3OqsIiM1oMeta?.alias || [],
    redirect: indexb3OqsIiM1oMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/terms-conditions-customer/index.vue").then(m => m.default || m)
  },
  {
    name: indexlInpzrF3ppMeta?.name ?? "pages-terms-conditions-mentor",
    path: indexlInpzrF3ppMeta?.path ?? "/pages/terms-conditions-mentor",
    meta: indexlInpzrF3ppMeta || {},
    alias: indexlInpzrF3ppMeta?.alias || [],
    redirect: indexlInpzrF3ppMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/terms-conditions-mentor/index.vue").then(m => m.default || m)
  },
  {
    name: new_45userlj8A6ibSYfMeta?.name ?? "pages-users-new-user",
    path: new_45userlj8A6ibSYfMeta?.path ?? "/pages/users/new-user",
    meta: new_45userlj8A6ibSYfMeta || {},
    alias: new_45userlj8A6ibSYfMeta?.alias || [],
    redirect: new_45userlj8A6ibSYfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/users/new-user.vue").then(m => m.default || m)
  },
  {
    name: reportsrF56HoxgkbMeta?.name ?? "pages-users-reports",
    path: reportsrF56HoxgkbMeta?.path ?? "/pages/users/reports",
    meta: reportsrF56HoxgkbMeta || {},
    alias: reportsrF56HoxgkbMeta?.alias || [],
    redirect: reportsrF56HoxgkbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/users/reports.vue").then(m => m.default || m)
  },
  {
    name: widgetsxhpjHu4ZNeMeta?.name ?? "pages-widgets",
    path: widgetsxhpjHu4ZNeMeta?.path ?? "/pages/widgets",
    meta: widgetsxhpjHu4ZNeMeta || {},
    alias: widgetsxhpjHu4ZNeMeta?.alias || [],
    redirect: widgetsxhpjHu4ZNeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/pages/widgets.vue").then(m => m.default || m)
  },
  {
    name: index5DK1w8Ek0CMeta?.name ?? "payments-id-record-session-balance",
    path: index5DK1w8Ek0CMeta?.path ?? "/payments/:id()/record-session-balance",
    meta: index5DK1w8Ek0CMeta || {},
    alias: index5DK1w8Ek0CMeta?.alias || [],
    redirect: index5DK1w8Ek0CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/payments/[id]/record-session-balance/index.vue").then(m => m.default || m)
  },
  {
    name: indexGCmf3Io0KOMeta?.name ?? "payments",
    path: indexGCmf3Io0KOMeta?.path ?? "/payments",
    meta: indexGCmf3Io0KOMeta || {},
    alias: indexGCmf3Io0KOMeta?.alias || [],
    redirect: indexGCmf3Io0KOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexno0yTBxb9pMeta?.name ?? "profile-edit-profile",
    path: indexno0yTBxb9pMeta?.path ?? "/profile/edit-profile",
    meta: indexno0yTBxb9pMeta || {},
    alias: indexno0yTBxb9pMeta?.alias || [],
    redirect: indexno0yTBxb9pMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/profile/edit-profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaxDlu2lUiuMeta?.name ?? "profile",
    path: indexaxDlu2lUiuMeta?.path ?? "/profile",
    meta: indexaxDlu2lUiuMeta || {},
    alias: indexaxDlu2lUiuMeta?.alias || [],
    redirect: indexaxDlu2lUiuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAkgWvXEWaMeta?.name ?? "rates-statistics",
    path: indexPAkgWvXEWaMeta?.path ?? "/rates-statistics",
    meta: indexPAkgWvXEWaMeta || {},
    alias: indexPAkgWvXEWaMeta?.alias || [],
    redirect: indexPAkgWvXEWaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/rates-statistics/index.vue").then(m => m.default || m)
  },
  {
    name: indexWYyTdUeoehMeta?.name ?? "rates-coaches-by-customers-id-details",
    path: indexWYyTdUeoehMeta?.path ?? "/rates/coaches-by-customers/:id()/details",
    meta: indexWYyTdUeoehMeta || {},
    alias: indexWYyTdUeoehMeta?.alias || [],
    redirect: indexWYyTdUeoehMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/rates/coaches-by-customers/[id]/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexrhL4lJuZCyMeta?.name ?? "rates-coaches-by-customers",
    path: indexrhL4lJuZCyMeta?.path ?? "/rates/coaches-by-customers",
    meta: indexrhL4lJuZCyMeta || {},
    alias: indexrhL4lJuZCyMeta?.alias || [],
    redirect: indexrhL4lJuZCyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/rates/coaches-by-customers/index.vue").then(m => m.default || m)
  },
  {
    name: index0NuwyQLgfTMeta?.name ?? "rates-customers-by-coaches",
    path: index0NuwyQLgfTMeta?.path ?? "/rates/customers-by-coaches",
    meta: index0NuwyQLgfTMeta || {},
    alias: index0NuwyQLgfTMeta?.alias || [],
    redirect: index0NuwyQLgfTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/rates/customers-by-coaches/index.vue").then(m => m.default || m)
  },
  {
    name: indexSyxMss3868Meta?.name ?? "request-update-coach-data-id-update-coach-data",
    path: indexSyxMss3868Meta?.path ?? "/request-update-coach-data/:id()/update-coach-data",
    meta: indexSyxMss3868Meta || {},
    alias: indexSyxMss3868Meta?.alias || [],
    redirect: indexSyxMss3868Meta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/request-update-coach-data/[id]/update-coach-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexp4p7RwKNlIMeta?.name ?? "request-update-coach-data",
    path: indexp4p7RwKNlIMeta?.path ?? "/request-update-coach-data",
    meta: indexp4p7RwKNlIMeta || {},
    alias: indexp4p7RwKNlIMeta?.alias || [],
    redirect: indexp4p7RwKNlIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/request-update-coach-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexyQIsSw4pMXMeta?.name ?? "sessions",
    path: indexyQIsSw4pMXMeta?.path ?? "/sessions",
    meta: indexyQIsSw4pMXMeta || {},
    alias: indexyQIsSw4pMXMeta?.alias || [],
    redirect: indexyQIsSw4pMXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/sessions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KGDdhtGjhgMeta?.name ?? "sessions-session-details-id",
    path: _91id_93KGDdhtGjhgMeta?.path ?? "/sessions/session-details/:id()",
    meta: _91id_93KGDdhtGjhgMeta || {},
    alias: _91id_93KGDdhtGjhgMeta?.alias || [],
    redirect: _91id_93KGDdhtGjhgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/sessions/session-details/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwneDLoD4FOMeta?.name ?? "settings-general-settings",
    path: indexwneDLoD4FOMeta?.path ?? "/settings/general-settings",
    meta: indexwneDLoD4FOMeta || {},
    alias: indexwneDLoD4FOMeta?.alias || [],
    redirect: indexwneDLoD4FOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/general-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexEP2oePFjhFMeta?.name ?? "settings-professional-data-settings",
    path: indexEP2oePFjhFMeta?.path ?? "/settings/professional-data-settings",
    meta: indexEP2oePFjhFMeta || {},
    alias: indexEP2oePFjhFMeta?.alias || [],
    redirect: indexEP2oePFjhFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/professional-data-settings/index.vue").then(m => m.default || m)
  },
  {
    name: edit_45roleOU9Em3sWGBMeta?.name ?? "settings-roles-permissions-id-edit-role",
    path: edit_45roleOU9Em3sWGBMeta?.path ?? "/settings/roles-permissions/:id()/edit-role",
    meta: edit_45roleOU9Em3sWGBMeta || {},
    alias: edit_45roleOU9Em3sWGBMeta?.alias || [],
    redirect: edit_45roleOU9Em3sWGBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/roles-permissions/[id]/edit-role.vue").then(m => m.default || m)
  },
  {
    name: indexzJHyoj3RGvMeta?.name ?? "settings-roles-permissions-add-role",
    path: indexzJHyoj3RGvMeta?.path ?? "/settings/roles-permissions/add-role",
    meta: indexzJHyoj3RGvMeta || {},
    alias: indexzJHyoj3RGvMeta?.alias || [],
    redirect: indexzJHyoj3RGvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/roles-permissions/add-role/index.vue").then(m => m.default || m)
  },
  {
    name: indexXS2vrQHBykMeta?.name ?? "settings-roles-permissions",
    path: indexXS2vrQHBykMeta?.path ?? "/settings/roles-permissions",
    meta: indexXS2vrQHBykMeta || {},
    alias: indexXS2vrQHBykMeta?.alias || [],
    redirect: indexXS2vrQHBykMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/roles-permissions/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqbQxUPRbhMeta?.name ?? "settings-session-settings-id-edit",
    path: indexmqbQxUPRbhMeta?.path ?? "/settings/session-settings/:id()/edit",
    meta: indexmqbQxUPRbhMeta || {},
    alias: indexmqbQxUPRbhMeta?.alias || [],
    redirect: indexmqbQxUPRbhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/session-settings/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexJOCAaXLUvHMeta?.name ?? "settings-session-settings",
    path: indexJOCAaXLUvHMeta?.path ?? "/settings/session-settings",
    meta: indexJOCAaXLUvHMeta || {},
    alias: indexJOCAaXLUvHMeta?.alias || [],
    redirect: indexJOCAaXLUvHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/session-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexdTzb5gvCKbMeta?.name ?? "settings-session-settings-new-session",
    path: indexdTzb5gvCKbMeta?.path ?? "/settings/session-settings/new-session",
    meta: indexdTzb5gvCKbMeta || {},
    alias: indexdTzb5gvCKbMeta?.alias || [],
    redirect: indexdTzb5gvCKbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/settings/session-settings/new-session/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJ23pzsoQtMeta?.name ?? "test",
    path: indexZJ23pzsoQtMeta?.path ?? "/test",
    meta: indexZJ23pzsoQtMeta || {},
    alias: indexZJ23pzsoQtMeta?.alias || [],
    redirect: indexZJ23pzsoQtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: indexHi2GZMKypLMeta?.name ?? "transactions-log",
    path: indexHi2GZMKypLMeta?.path ?? "/transactions-log",
    meta: indexHi2GZMKypLMeta || {},
    alias: indexHi2GZMKypLMeta?.alias || [],
    redirect: indexHi2GZMKypLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/transactions-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQIWTX1YtVMeta?.name ?? "users-id-edit",
    path: indexmQIWTX1YtVMeta?.path ?? "/users/:id()/edit",
    meta: indexmQIWTX1YtVMeta || {},
    alias: indexmQIWTX1YtVMeta?.alias || [],
    redirect: indexmQIWTX1YtVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/users/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexM0IykqdYtxMeta?.name ?? "users-id-show",
    path: indexM0IykqdYtxMeta?.path ?? "/users/:id()/show",
    meta: indexM0IykqdYtxMeta || {},
    alias: indexM0IykqdYtxMeta?.alias || [],
    redirect: indexM0IykqdYtxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/users/[id]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexdaD3OV0lmXMeta?.name ?? "users",
    path: indexdaD3OV0lmXMeta?.path ?? "/users",
    meta: indexdaD3OV0lmXMeta || {},
    alias: indexdaD3OV0lmXMeta?.alias || [],
    redirect: indexdaD3OV0lmXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexxJUAvMD6nbMeta?.name ?? "wallets-id-show",
    path: indexxJUAvMD6nbMeta?.path ?? "/wallets/:id()/show",
    meta: indexxJUAvMD6nbMeta || {},
    alias: indexxJUAvMD6nbMeta?.alias || [],
    redirect: indexxJUAvMD6nbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/wallets/[id]/show/index.vue").then(m => m.default || m)
  },
  {
    name: index5jJNzDt4qlMeta?.name ?? "wallets",
    path: index5jJNzDt4qlMeta?.path ?? "/wallets",
    meta: index5jJNzDt4qlMeta || {},
    alias: index5jJNzDt4qlMeta?.alias || [],
    redirect: index5jJNzDt4qlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/wallets/index.vue").then(m => m.default || m)
  },
  {
    name: coachesXgHEdgJRsnMeta?.name ?? "warning-coaches",
    path: coachesXgHEdgJRsnMeta?.path ?? "/warning/coaches",
    meta: coachesXgHEdgJRsnMeta || {},
    alias: coachesXgHEdgJRsnMeta?.alias || [],
    redirect: coachesXgHEdgJRsnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/warning/coaches.vue").then(m => m.default || m)
  },
  {
    name: customersiqSpExSBeaMeta?.name ?? "warning-customers",
    path: customersiqSpExSBeaMeta?.path ?? "/warning/customers",
    meta: customersiqSpExSBeaMeta || {},
    alias: customersiqSpExSBeaMeta?.alias || [],
    redirect: customersiqSpExSBeaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/warning/customers.vue").then(m => m.default || m)
  },
  {
    name: indexSQcBfLAbYYMeta?.name ?? "warning-reviewed",
    path: indexSQcBfLAbYYMeta?.path ?? "/warning/reviewed",
    meta: indexSQcBfLAbYYMeta || {},
    alias: indexSQcBfLAbYYMeta?.alias || [],
    redirect: indexSQcBfLAbYYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/calwe-admin/calwe-admin/pages/warning/reviewed/index.vue").then(m => m.default || m)
  }
]