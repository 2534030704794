<template>
  <div class="form-group mt-4 mb-0">
    <div :class="hasIcon(icon)">
      <label v-if="label" :for="id" class="custom-control-label float-end">
        {{ label }}
      </label>
      <div class="input-container">
        <input
          :id="id"
          :type="type === 'password' && passwordVisible ? 'text' : type"
          :max="{ beforeDate: type === 'date' }"
          class="form-control px-2"
          :name="name"
          :disabled="disabled"
          :class="getClasses(size, success, error, errorMessage)"
          :value="modelValue"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          min="0"
        />
        <!-- Add the show/hide password icon here -->
        <div
          v-if="type === 'password'"
          class="password-toggle-icon"
          @click="togglePasswordVisibility"
        >
          <i
            class="fas"
            :class="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
          ></i>
        </div>
      </div>
      <div v-if="errorMessage.length" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'ArgonInput',

  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    icon: {
      type: String,
      default: '',
    },
    iconDir: {
      type: String,
      default: 'left',
    },
    name: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      passwordVisible: false,
    };
  },
  emits: ['update:modelValue'],
  computed: {
    beforeDate() {
      const currentDate = moment(); // Get current date
      const newDate = currentDate.subtract(18, 'years').subtract(1, 'day'); // Subtract 18 years and 1 day

      return newDate.format('YYYY-MM-DD'); // Format the date as "yyyy-mm-dd"
    },
  },

  methods: {
    getClasses: (size, success, error, errorMessage) => {
      let sizeValue, isValidValue;
      // eslint-disable-next-line prefer-const
      sizeValue = size ? `form-control-${size}` : '';

      if (error || errorMessage.length) {
        isValidValue = 'is-invalid';
      } else if (success) {
        isValidValue = 'is-valid';
      } else {
        isValidValue = '';
      }

      return `${sizeValue} ${isValidValue}`;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    getIcon: (icon) => icon || '',
    hasIcon: (icon) => (icon ? 'input-group' : ''),
  },
};
</script>
<style lang="scss" scoped>
.input-container {
  display: flex;
  align-items: center;
  position: relative;
  display: block;
}

.password-toggle-icon {
  position: absolute;
  left: 10px; /* Adjust the value to control the icon's position */
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
