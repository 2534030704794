import {
  helpers,
  minLength,
  maxLength,
  required,
  email,
  sameAs,
  requiredIf,
  maxValue,
  between,
} from '@vuelidate/validators';

export const required$ = helpers.withMessage('هذا الحقل مطلوب', required);
export const requiredImage$ = helpers.withMessage('قم باختيار صورة', required);
export const maxValue$ = (max) =>
  helpers.withMessage(
    ({ $params }) => `يجب أن يكون الرقم أصغر من ${$params.max}`,
    maxValue(max)
  );

export const email$ = helpers.withMessage(
  'صيغة البريد الإلكتروني غير صحيحة',
  email
);
const passwordRegx = helpers.regex(
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#?&])[A-Za-z\d@$!%*^#?&]{8,}$/
);

const arabicRegx = helpers.regex(/^[a-zA-Z\ء-ي ]+$/);

const numRegx = helpers.regex(/^[1-9]\d*$/);
const numRegxIncludeZero = helpers.regex(/^[0-9]\d*$/);

export const password$ = helpers.withMessage(
  'كلمة المرور يجب أن تحتوي على حروف وأرقام ورموز خاصة',
  passwordRegx
);

export const alpha$ = helpers.withMessage(
  'يجب أن لا يحتوي النص على أرقام أو رموز',
  arabicRegx
);
export const noSpaces$ = helpers.withMessage(
  'يجب أن لا يحتوي النص على مسافات',
  (value) => !/\s/.test(value)
);
export const num$ = helpers.withMessage(
  'يجب أن تكون الأولوية عددًا صحيحًا',
  numRegx
);
export const number$ = helpers.withMessage(
  'يجب أن تكون القيمة عدداً صحيحاً, وأن تكون أكبر من الصفر',
  numRegx
);
export const phone$ = helpers.withMessage(
  'يجب أن لايحتوي رقم الهاتف على أحرف أو رموز',
  numRegxIncludeZero
);
export const requiredIf$ = (value) =>
  helpers.withMessage('هذا الحقل مطلوب', requiredIf(value));

export const maxLength$ = (max) =>
  helpers.withMessage(({ $params }) => {
    if ($params.max == 1 || $params.max == 2) {
      return $params.max == 1
        ? 'يجب أن لا يتجاوز طول النص حرف واحد.'
        : 'يجب أن لا يتجاوز طول النص حرفين.';
    } else if ($params.max >= 3 && $params.max <= 10) {
      return `يجب أن لا يتجاوز طول النص ${$params.max} أحرف.`;
    } else {
      return `يجب أن لا يتجاوز طول النص ${$params.max} حرفاً.`;
    }
  }, maxLength(max));
export const minLength$ = (min) =>
  helpers.withMessage(({ $params }) => {
    if ($params.min == 1 || $params.min == 2) {
      return $params.min == 1
        ? 'يجب أن يكون طول النص على الأقل حرف واحد.'
        : 'يجب أن يكون طول النص على الأقل حرفين.';
    } else if ($params.min >= 3 && $params.min <= 10) {
      return `يجب أن يكون طول النص على الأقل ${$params.min} أحرف.`;
    } else {
      return `يجب أن يكون طول النص على الأقل ${$params.min} حرفاً.`;
    }
  }, minLength(min));
export const maxLengthNumber$ = (max) =>
  helpers.withMessage(
    `يجب أن لا يتجاوز طول الرقم ${max} رقماً`,
    maxLength(max)
  );
export const minLengthNumber$ = (min) =>
  helpers.withMessage(
    `يجب أن يكون طول الرقم على الأقل ${min} ارقام`,
    minLength(min)
  );

export const sameAs$ = (password) =>
  helpers.withMessage(' كلمة المرور غير متطابقة', sameAs(password));

const notSameAs = (param) =>
  helpers.withParams(
    { type: 'notSameAs', value: param },
    (value) => !helpers.req(value) || value !== param
  );

export const notSameAs$ = (password) =>
  helpers.withMessage(
    'يجب أن لا تتطابق كلمة المرور الجديدة بـ كلمة المرور القديمة',
    notSameAs(password)
  );

const avatarSizeCheck = (value) => {
  if (!value) {
    return true;
  }
  const limitFileSize = 2 * 1024 * 1024;
  if (value.size > limitFileSize) {
    return false;
  }
  return true;
};

export const avatarSize$ = helpers.withMessage(
  'يحب أن لايتجاوز حجم الصورة 2MB',
  avatarSizeCheck
);

const imageSizeCheck = (value) => {
  if (!value) {
    return true;
  }
  const limitFileSize = 1 * 1024 * 1024;
  if (value.size > limitFileSize) {
    return false;
  }
  return true;
};

export const imageSize$ = helpers.withMessage(
  'يحب أن لايتجاوز حجم الصورة 1MB',
  imageSizeCheck
);

const dimensionsCheck = (_) => {
  //
};

export const imageDimensions$ = helpers.withMessage(
  'أبعاد الصورة يجب أن تكون على الأقل 400 *600 ويجب أن لا تتجاوز 1200*1800',
  dimensionsCheck
);

export const between$ = (value1, value2) =>
  helpers.withMessage(
    `يجب أن لاتقل النسبة عن ${value1} ولا تتجاوز ${value2}`,
    between(value1, value2)
  );

  const numbersWithSpecialCharacters = helpers.regex(
    /^[0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/
  );

  export const numbersWithSpecialCharacters$ = helpers.withMessage(
    'يجب أن تكون القيمة عدد أو رمز ',
    numbersWithSpecialCharacters
  )
  const englishAlphabeticWithNumbers = helpers.regex(/^[a-zA-Z0-9]*$/);

  export const englishAlphabeticWithNumbers$ = helpers.withMessage(
    'يجب أن تكون القيمة فقط أحرف إنجليزية وأرقام',
    englishAlphabeticWithNumbers
  );